@import '../../assets/css/variable.scss';
@import '../../assets/css/mixins.scss';

.paymentsPage {
    .paymentCard {
        @include card-box;

        &.cardDetails {
            margin-top: 2.4rem;

            .tab-content {
                margin-top: 1.6rem;
            }

            .nav-item {
                .nav-link {
                    min-width: 15.8rem;

                    @media screen and (max-width: $screen-lg) {
                        min-width: 14.8rem;
                    }
                }
            }
        }

        .headingBlock {
            h5 {
                font-size: 2rem;
                line-height: 2.8rem;
                font-weight: 400;
                padding-bottom: 1.2rem;
                border-bottom: 1px solid $offgrey;
                margin-bottom: 3.7rem;
            }
        }
    }

    .billingForm {
        .addAddress {
            @include flex-space-between;

            .saveAddress {
                @include flex-align-center;
                flex-wrap: nowrap;

                input {
                    margin: 0 1rem 0 0;
                }

                label {
                    margin: 0;

                    @media screen and (max-width: $screen-sm) {
                        font-size: 1.2rem;
                        line-height: normal;
                    }
                }
            }

            .btnGroup {
                @include flex-align-center;

                button {
                    margin: 0.5rem 0;
                }

                button:last-child {
                    margin-left: 1.6rem;
                }
            }

            .btn {
                width: auto;
                height: auto;
                min-width: 5.8rem;
                font-size: 1.2rem;
                line-height: normal;
                font-weight: 500;
                padding: 1rem 2rem;
            }
        }
    }

    .orderSummary {
        position: relative;
        height: 100%;
        padding-bottom: 8rem;

        .choosedPlan,
        .additionalCredits {
            @include flex-space-between;
            background: rgba(229, 84, 38, 0.05);
            border: 1px solid rgba(229, 84, 38, 0.20);
            border-radius: 0.8rem;
            padding: 1.6rem 2.4rem;
            margin-bottom: 0.8rem;

            .planName {
                font-weight: 500;
            }

            .planName,
            .addCredits {
                @include flex-align-center;
                color: $primary;

                input {
                    margin-right: 1.2rem;

                    &:checked {
                        &::before {
                            background: $primary;
                        }

                        &::after {
                            border-color: $white;
                        }
                    }
                }
            }

            .btn {
                width: auto;
                height: auto;
                background: transparent;
                font-size: 1.2rem;
                line-height: normal;
                font-weight: 500;

                &:hover,
                &:focus {
                    background: $secondary;
                }

                @media screen and (max-width: $screen-sm) {
                    margin: 0.5rem 0;
                    padding: 1rem;
                }
            }
        }

        .additionalCredits {
            background: $white;

            .countCredits {
                @include flex-align-center;
                flex-wrap: nowrap;
                background: $white;
                border-radius: 0.8rem;
                padding: 0.8rem;
                border: 1px solid rgba(250, 221, 212, 0.20);

                @media screen and (max-width: $screen-sm) {
                    margin: 0.5rem 0;
                }

                input {
                    border: none;
                    outline: none;
                    box-shadow: none;
                    text-align: center;
                    max-width: 8rem;
                    font-size: 1.4rem;

                    &:focus {
                        border: none;
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }

        .divider {
            width: 100%;
            height: 1px;
            background: $offgrey;
            margin: 2.5rem 0;
        }
    }

    .amountBlock {
        max-width: 26.2rem;
        margin-left: auto;
        margin: 3.2rem 0 3.2rem auto;

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                @include flex-space-between;

                &.totalAmount {
                    font-weight: 600;

                    span {
                        font-size: 2rem;
                        color: $primary;
                    }
                }
            }
        }
    }

    .payButton {
        position: absolute;
        width: calc(100% - 4.8rem);
        bottom: 2.4rem;
        left: auto;
        right: auto;
        margin-top: 2.4rem;
    }

    .addedAddresses,
    .addedCards {

        .addressBlock,
        .cardBlock {
            @include flex-space-between;
            flex-wrap: nowrap;
            background: $white;
            border: 1px solid $offgrey;
            border-radius: 0.8rem;
            padding: 1.6rem 2.4rem;
            margin-bottom: 0.8rem;

            @media screen and (max-width: $screen-sm) {
                padding: 1.6rem;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .btn {
                width: auto;
                height: auto;
                min-width: 5.8rem;
                font-size: 1.2rem;
                line-height: normal;
                font-weight: 500;
                padding: 1rem 2rem;
                background: transparent;
                display: none;

                &:hover {
                    background: $secondary;
                }

                @media screen and (max-width: $screen-sm) {
                    margin: 0.5rem 0;
                    padding: 1rem;
                }
            }

            span {
                font-size: 1.4rem;
                font-weight: 300;

                &.vatNumber {
                    margin-left: 1rem;
                }

                &.address,
                &.expireDate {
                    display: block;
                }
            }

            .planName,
            .cardName {
                @include flex-align-center;

                input::before {
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &.selected {
                background: rgba(229, 84, 38, 0.05);
                border: 1px solid rgba(229, 84, 38, 0.20);

                .btn {
                    display: block;
                }
            }
        }

        .addNew {
            margin-top: 2.4rem;
        }
    }

    .orderSummary {
        @media screen and (max-width: $screen-md) {
            margin-top: 2.4rem;
        }

        .table {
            border-radius: 0.8rem;
            overflow: hidden;

            th {
                background: $lightGreyBackground1;
                color: $offblack;

                &:first-child {
                    border-top-left-radius: 0.8rem;
                }

                &:last-child {
                    border-top-right-radius: 0.8rem;
                }
            }

            th,
            td {
                border: 2px solid $borderColor8;
            }
        }
    }

    .pay-payment-btn {
        width: fit-content;
        margin: auto;

        @media screen and (max-width: $screen-xs) {
        }
    }

    .nav-pills {
        .nav-item {
            margin: 0 1.6rem 1.2rem 0;

            &:last-child {
                margin-right: 0;

                @media screen and (max-width: $screen-xs) {
                    margin-top: 10px !important;

                }
            }

            @media screen and (max-width: $screen-xs) {
                margin: 0px !important;
                width: 100%;
            }
        }

        .nav-link {
            @include flex-align-center;
            position: relative;
            background: $white;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: rgba(229, 84, 38, 0.80);
            border-radius: 0.8rem;
            padding: 2rem 1.6rem;
            border: 1px solid rgba(229, 84, 38, 0.20);

            .circle {
                position: relative;
                display: block;
                width: 2rem;
                height: 2rem;
                border: 2px solid rgba(229, 84, 38, 0.80);
                border-radius: 100%;
                margin-right: 1.2rem;

                &::before {
                    @include before-after;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 1rem;
                    height: 1rem;
                    background: $primary;
                    border-radius: 100%;
                    opacity: 0;
                }
            }

            &.active {
                background: $primary2;
                color: $primary;

                .circle::before {
                    opacity: 1;
                }
            }
        }

        .show>.nav-link {
            background: $primary2;
            color: $primary;
        }
    }
}