.freepageContentBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.freecontentWrapper {
  width: 1200px; 
  margin: auto;
}

.bg-Image {
  background-image: url('../../../../assets/images/prospect.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  height: 540px; /* Set the height of the background image */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px; 
  color: white; /* Text color */
  text-align: center;
  padding: 20px;
  position: relative; 
}

.text-Overlay {
  font-size: 2rem; /* Adjust size as needed */
  margin-bottom: 10px;
    /* added */
    position: fixed; 
    top: 300px;
    z-index: 9999 !important;
}

.cta-Button {
  padding: 6px 10px;
  font-size: 1rem;
  color: white;
  background-color: #E55426; /* Change to your preferred color */
  border: none;
  border-color: #E55426;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 4.4rem auto 0;
  max-width: 24.1rem;
    /* added */
    position: fixed;
    top: 308px;
    z-index: 999;

}

.cta-Button:hover {
  background-color: rgb(238, 151, 110);
   
}
@media screen and (max-width: 480px) {
  .bg-Table {
    position: relative;
  }
  .text-Overlay {
    font-size: 1.5rem !important;/* Adjust size as needed */
    margin-bottom: 10px;
    /* added */
    position: absolute; 
    top: 150px;
    z-index: 9999 !important;
}
.cta-Button{
     /* added */
     position: absolute;
     top: 255px;
     z-index: 999;
}
}
