@import '../../../assets/css/variable.scss';
@import '../../../assets/css/mixins.scss';

.NewSupportTicket {
    @include card-box;
    padding: 4rem 9rem;

    @media screen and (max-width: $screen-sm) {
        padding: 4rem 2.4rem;
    }

    h4 {
        font-size: 2.2rem;
        line-height: normal;
        font-weight: 500;
        margin-bottom: 3.2rem;
    }

    form {
        textarea.form-control {
            border-radius: 0.8rem;
            border: 1px solid $neutralGrey;
            height: 15rem;
            padding: 2.4rem;
        }
    }

    @include upload-document;

    .raiseTicket {
        .multiSelectionLabel {
            @include flex-space-between;

            a {
                color: $offblack;
                font-size: 1.4rem;
                line-height: 2rem;
                margin-left: 1rem;

                &:hover,
                &:focus {
                    color: $primary;
                }
            }
        }

        .uploadedFile {
            margin: 1.2rem 0 0;
        }

        .formSubmit {
            .btn {
                min-width: 26.5rem;
                margin: auto;
                font-weight: 500;
            }
        }

        .multiSelectContainer {
            .searchWrapper {
                width: 100%;
                height: auto;
                min-height: 4.8rem;
                padding: 1.2rem 1.2rem 1.2rem 0;
                background: #ffffff;
                font-family: "Poppins", sans-serif;
                font-size: 1.6rem;
                line-height: 2.4rem;
                font-weight: 400;
                color: #414141;
                border: none;
                border-radius: 0;
                border-bottom: 1px solid #E6E6E6;
                margin: 0;

                input {
                    margin: 0;
                    padding: 0;
                }

                .chip {
                    background: $primary;
                    border-radius: 0.4rem;
                    padding: 0.2rem 0.6rem;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.multiSelectContainer .optionListContainer {
    border: none;
    border-radius: 1.2rem;
    background: $white;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.10);

    ul {
        border: none;
        padding: 2.4rem;
        margin: 0;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.8rem 1rem;

        @media screen and (max-width: $screen-sm) {
            grid-template-columns: 1fr;
        }

        li {
            @include flex-align-center;
            background: $white;
            font-size: 1.4rem;
            line-height: 2.4rem;
            color: $secondary;
            padding: 0;
            margin: 0;

            &.selected {
                background: $white;
                color: $secondary;
            }

            input {
                margin-top: 0;
                margin-right: 1.6rem;

                &:checked {
                    &::before {
                        background: $primary;
                    }

                    &::after {
                        border-color: $white;
                    }
                }
            }
        }
    }
}

.error {
    color: red;
}

.selectWidth100 {
    width: 100%;
}

.NewSupportTicket .uploadedFile .fileDetilsItem{
    @media screen and (max-width: $screen-sm) {
        justify-content: space-between;
        flex-direction: row;
     }

     @media screen and (max-width: $screen-md) {
        justify-content: space-between;
        flex-direction: row;
     }

     @media screen and (max-width: $screen-xs) {
        justify-content: space-between;
        flex-direction: row;
     }

}