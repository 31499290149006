@import '../../../assets/css/variable.scss';
@import '../../../assets/css/mixins.scss'; 

.findProspectDetails{
    @include card-box;
    padding: 0;
    .tableContentBlock .statusBox{
        display: inline-flex;
        min-width: 12rem;
    }
    .downloadSelected .btn{
        padding: 1rem 1.6rem;
        border: 1px solid transparent;
        cursor: pointer;
        border-radius: 0.8rem;
        color: $secondary;
        svg{
            margin-right: 0.8rem;
        }
        &:focus{
            border-color: $borderColor1;
        }
        @media screen and  (max-width: $screen-sm) {
            border-radius: 0.8rem;
            border-color: $borderColor1;
            margin: 0 0.5rem 0.5rem 0;
            padding: 1rem 1rem;
        }
    }
    .disabled {
        pointer-events: none; /* Prevents clicks */
        opacity: 0.5; /* Makes it visually inactive */
      }
}
// .findProspectDetails table tbody tr:hover td, .findProspectDetails table tbody tr:focus td {
//     background: #FEF5EC;
//     border-color: rgba(245, 157, 64, 0.2);


// }
// .findProspectDetails table tbody tr:hover td:first-child::before, .findProspectDetails table tbody tr:focus td:first-child::before {
//     content: "";
//     position: absolute;
//     pointer-events: none;
//     left: 0;
//     top: 0;
//     width: 3px;
//     height: 100%;
//     background: #F5A243;
// }


.findProspectDetails table tbody tr:hover {
    // box-shadow: 0px 9px 4px -6px orange;
    // transform: scaleX(1.02);
}

.findProspectDetails table tbody tr:hover td {
    background: #FEF5EC;
    box-shadow: none; /* Reset box-shadow for td */
}

.findProspectDetails table tbody tr:hover td:first-child::before {
    content: "";
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 3px;
    height: 100%;
    background: #F5A243;
}
// added by Nandini
.icon-style{
    // cursor: pointer;
    color: #e55426;
    font-size:1.4rem;
    transition: transform 0.3s ease;
}
.icon-style:hover{
    transform: scale(1.2);
 
}
.blurred {
    color: grey; /* Change to grey */
    filter: blur(.6px); /* Apply blur effect */
    pointer-events: none; /* Optional: Prevent hover events */
}


// .tooltip-container {
//     position: relative;
//     display: inline-block;
//   }
  
//   .custom-tooltip {
//     visibility: hidden;
//     width: 100px;
//     background-color: #333;
//     color: #fff;
//     text-align: center;
//     padding: 5px 10px;
//     border-radius: 4px;
//     position: absolute;
//     z-index: 1;
//     bottom: 125%; 
//     left: 50%;
//     margin-left: -50px;
//     opacity: 0;
//     transition: opacity 0.3s;
//     font-size: .8rem;
//   }
//   .tooltip-container .custom-tooltip::after {
//     content: "";
//     position: absolute;
//     top: 100%;
//     left: 50%;
//     transform: translateX(-50%);
//     border-width: 5px;
//     border-style: solid;
//     border-color: #333 transparent transparent transparent;
//   }
//   .tooltip-container:hover .custom-tooltip {
//     visibility: visible;
//     opacity: 1;
//   }
  