@import "../../assets/css/variable.scss";
@import "../../assets/css/mixins.scss";

.ToastModal {

  // Define modal content padding
  .modal-content {
    padding-top: 4rem;
    padding-bottom: 4rem;
    border-radius: 1.2rem; // Rounded corners for the modal
  }

  // Success Modal Styling
  &.successModal {
    .modal-header {
      border-bottom: 0;
      padding: 1.6rem 2.4rem;
      background-color: #e8f5e9; // Light green background

      .modal-title {
        color: #388e3c; // Dark green title color
        font-weight: bold;
      }
    }

    .modal-body {
      text-align: center;
      padding: 2.4rem 3.2rem;
      color: #388e3c;
      font-size: 1.4rem;
    }

    .successIcon {
      width: 7.8rem;
      height: 7.8rem;
      object-fit: contain;
      margin: 0 auto 2.4rem;
    }

    p {
      font-weight: 500;
      margin: 0;
      font-size: 1.4rem;
      color: #4caf50; // Success green
    }

    .btn {
      display: inline-flex;
      width: auto;
      min-width: 10.8rem;
      margin-top: 3.2rem;
      background-color: #388e3c; // Button color matching the theme
      border-radius: 2.4rem;
      padding: 1rem 2.4rem;
      color: #fff;

      &:hover {
        background-color: #2c6b2f; // Darker green on hover
      }

      &:focus {
        outline: none;
      }
    }
  }

  // Error Modal Styling
  &.errorModal {

    .modal-header {
      border-bottom: 0;
      padding: 1.6rem 2.4rem;
      background-color: #ffff; // Light red background

      .modal-title {
        color: #E55426; // Red title color
        font-weight: bold;
        text-align: center;
        margin: 2px 74px 0rem;
      }
    }

    .modal-body {

      text-align: center;
      padding: 2.4rem 3.2rem;
      color: #0000;
      font-size: 1.4rem;
    }

    .errorIcon {
      width: 7.8rem;
      height: 7.8rem;
      object-fit: contain;
      margin: 0 auto 2.4rem;
    }

    p {
      font-weight: 500;
      margin: 5px;
      font-size: 1.4rem;
      color: #615d5d; // Error red
    }

    .btn {
      display: inline-flex;
      width: auto;
      min-width: 10.8rem;
      // margin-top: 3.2rem;
      background-color: #E55426; // Button color matching the theme
      border-radius: 2.4rem;
      padding: 1rem 2.4rem;
      color: #fff;
      svg path {
        fill: #fff; /* Change this to your desired hover color */
      }
      

      &:hover {
        background-color: #f1e6e6; // Darker red on hover
        color: #E55426;
        svg path {
          fill: #E55426; /* Change this to your desired hover color */
        }
      }

      &:focus {
        outline: none;
      }
    }
  }

  // Common modal styling
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 2.4rem;
    background-color: #f5f5f5;
    border-radius: 1.2rem 1.2rem 0 0;
  }

  .modal-body {
    padding: 2.4rem 3.2rem;
  }

  // Overlay (background blur)
  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }
}