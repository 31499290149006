@import "../../../assets/css/variable.scss";
@import "../../../assets/css/mixins.scss";

.change-password-page .formWrapper {
  max-width: 55.4rem;
  margin: auto;
  padding: 5rem 0;
  h2 {
    text-align: center;
  }

  .smText{
    margin-bottom: 2rem;
  }

  .fa-eye-slash,
  .fa-eye {
    position: absolute;
    // bottom: 1.5rem;
    right: 1.2rem;
    color: #b3b3b3;
  }
  

  .passwordHint {
    font-size: 1.4rem;
    line-height: 2rem;
    color: #a0a0a0;
  }

  .passwordHint strong {
    font-weight: 500;
  }
  /* text-align: center; */
}
.CreditCardBLk {
  .creditsContent {
    &.creditsContentblk {
      // margin-top: 2px;
      .spendingTime {
        @media screen and (max-width:767px) {
          margin-top: 15px !important;
          padding: 0 !important;
        }
      }
    }
  }
}
.TableBLK {
  @media screen and (max-width:767px) {
    gap: 20px !important;
  }

}
.download_blockk {
  &.downloadBLK {
    @media screen and (max-width:767px) {
      position: inherit !important;
      top: inherit !important;
      right: inherit !important;
    }
  }
}

.geo-dropdown {

  label {
    @media screen and (max-width:767px) {
      font-size: 10px !important;
    }
  }
  select {
    @media screen and (max-width:767px) {
      width: 80px;
      padding: 2px 5px !important;
      font-size: 9px !important;
    }
  }
}

  .pageHeadingBlock.commonHeading.campSpace {
    h6 {
      margin-bottom: 0;
    }
    .icpDataList.download_list_block {
      li {
        @media screen and (max-width: 670px) {
          // margin-bottom: 5px;
        }
      }

    }
  }
