@import "../../../assets/css/variable.scss";
@import "../../../assets/css/mixins.scss";

.profileSettingsPage{
    .userPersonalDetails{
        background: $white;
        padding: 2.4rem;
        border-radius: 1rem;
        margin: 0;
        .headingBlock{
            @include flex-space-between;
            padding-bottom: 1.2rem;
            border-bottom: 1px solid $offgrey;
            margin: 0 0 3.2rem;
            h5{
                font-size: 2rem;
                line-height: 2.8rem;
                font-weight: 400;
                margin: 0;
                @media screen and  (max-width: $screen-lg) {
                    font-size: 1.6rem;
                    line-height: 2.4rem; 
                    font-weight: 500;
                }
            }
        }
        .userDetailsUpdate{
            @include image-upload;
            .editUserImage{
                background: $borderColor7;
                border-radius: 0.8rem;
                padding: 1.6rem;
                @include flex-justify-center;
                .userImage{
                    position: relative;
                    width: 16rem;
                    height: 16rem;
                    border-radius: 100%;
                   
                    .userNameLetter{
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        color: $white;
                        font-size: 9.3rem;
                        line-height: normal;
                        font-weight: 500;
                        margin: 0;
                        background: $orange3;
                    }
                    img{
                        width: 16rem;
                        height: 16rem;
                        border-radius: 100%;
                    }
                    .editImage{
                        position: absolute;
                        right: 1rem;
                        bottom: 1rem;
                        width: 3.2rem;
                        height: 3.2rem;
                        border-radius: 100%;
                        background: $white;
                        filter: drop-shadow(0px 5.333px 5.333px rgba(0, 0, 0, 0.07));
                        padding: 0.6rem;
                        @include flex-justify-center;
                        cursor: pointer;
                    }
                }
            }
            .btn{
                width: auto;
                min-width: 14rem;
                margin: auto;
                svg{
                    margin-right: 0.8rem;
                }
            }
        }
    }
    &.sideContentWrapper{
        @media (min-width: $screen-sm) and  (max-width: $screen-md) {
            margin-top: 2.4rem;
        }
        .contentCard{
            flex-direction: column;
        }
        .row:first-child{
            .col-12{
                margin-bottom: 2.4rem;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .businessUpdate{
            .formSubmit{
                margin: 0;
            }
            .btn{
                font-size: 1.4rem;
                min-width: 14rem;
                margin: auto;
                @media screen and  (max-width: $screen-sm) {
                        font-size: 1.2rem;
                        max-width: 16px;
                        padding: 20px;
                        width: auto;
                        min-width: 14rem;
                        margin: auto;
                }
                svg{
                    margin-right: 0.8rem;
                }
            }
        }
        a{
            font-weight: 600;
        }
    }
}

.btn-update{
    gap: 10px;
}