@import '../../../../assets/css/variable.scss';
@import '../../../../assets/css/mixins.scss';

.AddProfilePictureModal{
    &.modal .modal-dialog .modal-body{
        max-width: 100%;
    }
    .userImage{
        margin: 2.4rem auto 0;
    }
    .buttonDiv{
        margin: 2.4rem 0 0;
    }
    .buttonGroup{
        margin: 2.4rem 0 0;
        .btn{
            width: 100%;
        }
    }
    .getStarted{
        // min-width: 26rem;
        margin-left:0rem;
    }
}
.cropped-image-preview{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

