@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import '~bootstrap/scss/bootstrap';

@import "./assets/css/variable.scss";
@import "./assets/css/mixins.scss";
@import "./assets/css/common.scss";

* {
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 1px transparent;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background: $lighBlue;
  font-family: $fontPoppins;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: $secondary;
  font-weight: normal;
  font-style: normal;
}

.container-fluid {
  width: 100%;
  //  max-width: 144rem;
  padding: 0 3.2rem;

  // margin: 0;
  // padding: 0;
  @media screen and (max-width: $screen-lg) {
    // padding: 0 1.6rem;
    padding: 0 0rem;
  }
}

.row {
  margin: 0 -1.2rem;

  .col-12 {
    padding: 0 1.2rem;
  }
}

p {
  font-family: $fontPoppins;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $secondary;
  font-weight: 600;
  margin: 0 0 1.6rem;
  font-family: $fontPoppins;
}

h1 {
  font-size: 4.4rem;
  line-height: 5.2rem;
  font-weight: 700;

  @media screen and (max-width: $screen-sm) {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
}

h2 {
  font-size: 3.2rem;
  line-height: 4rem;
  font-weight: 600;

  @media screen and (max-width: $screen-sm) {
    font-size: 2.8rem;
    line-height: 3.4rem;
  }

  @media screen and (max-width: $screen-xs) {
    font-size: 2.6rem;
    line-height: 3rem;
  }
}

h3 {
  font-size: 2rem;
  line-height: 3.2rem;
  font-weight: 500;

  @media screen and (max-width: $screen-sm) {
    font-size: 2rem;
    line-height: 2.8rem;
    text-align: center
  }
}

h4 {
  font-size: 2.1rem;
  line-height: 3.2rem;

  @media screen and (max-width: $screen-sm) {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

h5 {
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: 500;
}

h6 {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
}

a {
  color: $primary;
  font-family: $fontPoppins;
  // color: #414141;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $secondary;
  }
}

img {
  // display: block;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

form {
  label {
    font-family: $fontPoppins;
    font-size: 1.6rem;
    line-height: normal;
    font-weight: 500;
    color: $secondary;
    margin: 0 0 0.5rem;
  }

  .form-group {
    position: relative;
    margin: 0 0 2.4rem;
  }

  .form-control {
    width: 100%;
    height: 4.8rem;
    padding: 1.2rem 1.2rem 1.2rem 1.2rem;
    background: $white;
    font-family: $fontPoppins;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 400;
    color: $secondary;
    border: none;
    border-radius: 6px;
    border-bottom: 1px solid $neutralGrey;
    margin: 0;

    &::placeholder {
      color: $grey;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    &:focus {
      border-color: $neutralGrey;
      box-shadow: none;
      outline: none;
    }
  }

  .required {
    color: $red;
  }

  .error-message {
    // position: absolute;
    // left: 1.2rem;
    // bottom: -2rem;
    color: $red;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}

.react-tel-input {
  .form-control {
    width: 100%;
    height: 4.8rem;
    padding: 1.2rem 1.2rem 1.2rem 4rem;
    background: $white;
    font-family: $fontPoppins;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 400;
    color: $secondary;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $neutralGrey;
    margin: 0;
  }

  .flag-dropdown,
  .flag-dropdown.open {
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $neutralGrey;
  }

  .selected-flag {
    padding: 0;
  }
}

.checkBox.checkbox_BLOck {

  input[type="checkbox"],
  .form-check input[type="checkbox"] {
    appearance: none;
    &:indeterminate {
      &::before {
        background-color: $primary; // Same background color as checked
        border-color: darken($primary, 10%); // Same border color as checked
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 9px;
        left: 5px;
        width: 10px;
        height: 2px;
        background-color: #fff; // White line for indeterminate state
        border-radius: 0.2rem; // Slight rounding of the line
        transition: transform 0.2s ease;
      }
    }
  }
}

input[type="checkbox"],
.form-check input[type="checkbox"] {
  position: relative;
  width: 2rem;
  height: 2rem;
  appearance: none;
 



  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 2.5px;
    background-color: transparent;
    border: 1.25px solid $primary;
    border-radius: 0.4rem;
    cursor: pointer;
  }

  &:checked {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 7px;
      width: 6px;
      height: 11px;
      border: solid $primary;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

 
}

input[type="radio"] {
  position: relative;
  width: 2rem;
  height: 2rem;
  appearance: none;
  margin-right: 1.2rem;

  @media screen and (max-width: $screen-sm) {
    margin-right: 0.6rem;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background-color: transparent;
    border: 2px solid $offgrey;
    cursor: pointer;
  }

  &:checked {
    &::before {
      border-color: $primary;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1rem;
      height: 1rem;
      background: $primary;
      border-width: 0 2px 2px 0;
      border-radius: 100%;
    }
  }
}

// input[type="radio"] {
//   width: 2rem;
//   height: 2rem;
//   border-radius: 100%;
//   border: 2px solid $offgrey;
//   opacity: 0;
//   position: absolute;
//   left: 0;
//   top: 2px;
//   z-index: 9;
//   cursor: pointer;

//   &+label {
//     position: relative;
//     padding-left: 3.2rem;
//     padding-right: 1rem;
//     @include flex-align-center;
//     cursor: pointer;

//     &::before {
//       content: '';
//       position: absolute;
//       left: 0;
//       top: 2px;
//       width: 2rem;
//       height: 2rem;
//       border-radius: 100%;
//       background-color: transparent;
//       border: 2px solid $offgrey;
//       cursor: pointer;
//       margin-right: 1.2rem;

//       @media screen and (max-width: $screen-sm) {
//         width: 1.6rem;
//         height: 1.6rem;
//         margin-right: 0.6rem;
//       }
//     }

//     @media screen and (max-width: $screen-sm) {
//       font-size: 1.4rem;
//       line-height: 2rem;
//     }
//   }

//   &:checked {
//     &+label {
//       &::before {
//         border-color: $primary;
//       }

//       &::after {
//         content: '';
//         display: block;
//         position: absolute;
//         top: 7px;
//         left: 5px;
//         width: 10px;
//         height: 10px;
//         background: $primary;
//         border-width: 0 2px 2px 0;
//         border-radius: 100%;

//         @media screen and (max-width: $screen-sm) {
//           width: 8px;
//           height: 8px;
//           top: 6px;
//           left: 4px;
//         }
//       }
//     }
//   }
// }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input:-internal-autofill-selected {
  appearance: none;
  background-image: none;
  background: $white;
  color: $secondary;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  color: $secondary;
  -webkit-text-fill-color: $secondary;
  box-shadow: none;
  -webkit-box-shadow: none;
  background: $white;
}

input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus {
  color: $secondary;
  -webkit-text-fill-color: $secondary;
  box-shadow: none;
  -webkit-box-shadow: none;
  background: $white;
}

input:-ms-autofill,
input:-ms-autofill:hover,
input:-ms-autofill:focus {
  color: $secondary;
  -webkit-text-fill-color: $secondary;
  box-shadow: none;
  -webkit-box-shadow: none;
  background: $white;
}

// .form-check{
//   padding: 0;
//   .form-check-input{
//     position: relative;
//     width: 2.8rem;
//     height: 1.4rem;
//     border-color: $primary;
//     background-image: none;
//     margin: 0;
//     cursor: pointer;
//     &::before{
//       @include before-after;
//       left: 2px;
//       top: 2px;
//       width: 8px;
//       height: 8px;
//       border-radius: 100%;
//       background: $primary;
//       transition: 0.15s ease-in-out;
//     }
//     &:checked {
//       background-color: $primary;
//       border-color: $primary;
//       &::before{
//         left: 16px;
//         background: $white;
//       }
//       &::after{
//         display: none;
//       }
//     }
//     &:hover, &:focus{
//       box-shadow: none;
//     }
//   }
// }

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background: $borderColor5;
  outline: none;
  border-radius: 4px;
}

.btn,
button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 4.3 rem;
  padding: 1.2rem 1.8rem;
  background: $primary;
  border: 1px solid $primary;
  border-radius: 10rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: $white;
  outline: none;
  box-shadow: none;
  // svg {
  //   path {
  //     fill: white;
  //   }
  // }

  @media screen and (max-width:998px) {
    font-size: 1.2rem !important;
    // line-height: 16px !important;
  }

  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    background: $primary;
    border-color: $secondary;
    color: $white;
    //  svg {
    // path {
    //   fill: white;
    // }
    // }
  }

  button:disabled {
    color: grey;
    background-color: gray !important;
    // svg {
    //   path {
    //     fill:grey
    //   }
    // }

  }

  &.btnPrimaryOutline {
    background: $white;
    color: $primary;

    &:hover,
    &:focus {
      background: $primary;
      border-color: $secondary;
      color: $white;
    }
  }

  &.transparentBtn {
    background: transparent;
    width: auto;
    height: auto;
    border-color: transparent;
    padding: 0;
    color: $primary;
    font-weight: 400;

    svg {
      margin-right: 0.8rem;
    }
  }
}

.mainContent {
  padding-top: 8.4rem;
  min-height: calc(100vh - 20rem);

  @media screen and (max-width: $screen-lg) {
    padding: 9.4rem 0;
    padding-bottom: 5px;
  }

  @media screen and (max-width: $screen-sm) {
    min-height: calc(100vh - 6.2rem);
  }
}

table,
.table {
  width: 100%;
  margin: 0;
  vertical-align: middle;
  position: relative;

  th,
  td {
    font-size: 1.2rem;
    line-height: 2rem;
    color: $secondary;
    font-weight: normal;
    padding: 1rem;
    // &:last-child {
    //   text-align: right;
    // }

    @media screen and (max-width: $screen-lg) {
      white-space: nowrap;
      // padding: 9px !important;
    }
  }

  th {
    font-weight: 500;
    font-size: 13px;
  }

  td {
    white-space: normal;
  }
}

.modal {
  .modal-dialog {
    &.modal-lg {
      max-width: 66rem;
    }

    .modal-content {
      background: $white;
      border-radius: 1rem;
      padding: 3.2rem 2.4rem;

      @media screen and (max-width: $screen-xs) {
        padding: 2rem 1.6rem;
      }
    }

    .modal-body {
      max-width: 44rem;
      padding: 0;
      margin: auto;

      h2 {
        font-size: 2.4rem;
        line-height: 3.6rem;
        color: $primary;
        font-weight: 500;
        margin: 0 0 2.4rem;

        @media screen and (max-width: $screen-xs) {
          font-size: 2rem;
          line-height: 2.8rem;
        }
      }
    }

    .closeModal {
      position: absolute;
      top: 3.2rem;
      right: 3.2rem;
      z-index: 99;
      cursor: pointer;

      @media screen and (max-width: $screen-xs) {
        top: 1rem;
        right: 1rem;
      }
    }
  }
}

.dropdown-menu {
  @include dropdown-menu;
}

.rangeSlides {
  padding: 0 2.4rem;
}

.rc-slider {
  .rc-slider-rail {
    height: 2px;
    background-color: rgba(229, 84, 38, 0.50);
    border-radius: 30px;
  }

  .rc-slider-track,
  .rc-slider-tracks {
    background: $primary;
    height: 2px;
    border-radius: 30px;
  }

  .rc-slider-step {
    height: 2px;
  }

  .rc-slider-handle {
    width: 16px;
    height: 16px;
    background: $primary;
    border: none;
    filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.16));
    opacity: 1;
    margin-top: -7px;

    &:active,
    &:hover,
    &:focus,
    &:focus-visible {
      border: none;
      box-shadow: none;
      border: none;
      outline: none;
    }
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: rgba(229, 84, 38, 0.1);
    box-shadow: 0 0 0 5px rgba(229, 84, 38, 0.2);
  }
}

.rangeInputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.6rem;

  .inputGroup {
    margin: 2.5rem 0 0;

    input {
      width: 100%;
      background: $borderColor7;
      padding: 0.6rem 1.6rem;
      border-radius: 0.8rem;
      height: 5.3rem;
      @include flex-align-center;
      font-size: 1.6rem;
      line-height: 2.4rem;
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
}

.fullscreen {
  background: $lighBlue;
  overflow-y: auto
}

.text-center {
  text-align: center;
}

.rc-slider .rc-slider-handle {
  z-index: -1;
}

.tableContentBlock {
  max-height: calc(100vh - 40rem);
}

.fullscreen .tableContentBlock.table-responsive {
  max-height: 100vh;
  padding-bottom: 5.4rem;

  @media screen and (max-width: $screen-xs) {
    padding-bottom: 0rem;
  }
}

.report-text {
  color: $primary;

  @media screen and (max-width: 1270px) {
    font-size: 1.3rem;
  }

  @media screen and (max-width: 1158px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 1080px) {
    font-size: 1rem;
  }
}

.record-head {
  @media screen and (max-width: 1427px) {
    font-size: 1.4rem;
  }

  @media screen and (max-width: 1270px) {
    font-size: 1.3rem;
  }

  @media screen and (max-width: 1158px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 1080px) {
    font-size: 1rem;
  }
}

.contentCard.creditsCard .btn,
button {

  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.3rem;
  padding: 1.2rem 1.8rem;
  background: $primary;
  border: 1px solid $primary;
  border-radius: 10rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: $white;
  outline: none;
  box-shadow: none;
  // svg {
  //   path {
  //     fill: white;
  //   }
  // }

}

.BuildMyCampBLK {
  margin: 0 45px 0 30px !important;

  @media screen and (max-width:1200px) {
    margin: 0 !important;
  }
}

.exportBlock {
  align-items: center;

  @media screen and (max-width:576px) {
    justify-content: start !important;
  }
}

.pieChart {
  &.piechartBLK {
    div {
      div {
        div {
          div {
            div {
              @media screen and (max-width:767px) {
                left: -50px !important;
              }
            }
          }
        }
      }
    }
  }
}

// Notification issue

.icon {
  &.iconBlk {
    width: 4rem !important;
    height: 4rem !important;
    font-size: 4rem !important;
  }
}

// Campaign Header

.campBLOCK {
  ul.icpDataList.download_list_block:has(li.is-free-trial) {
    margin: 0px;
    /* Adjust the margin as needed */
  }

  .pageTitle {
    // width: 30%;
    // white-space: nowrap;

    // @media screen and (max-width:1199px) {
    //   width: inherit;
    //   margin-bottom: 6px;
    // }
    // &:has(.download_blockk.is-free-trial) {
    //   @media screen and (max-width:1199px) {
    //     width: 50%;
    //   }
    // }
  }

  // .pageTitle + .campINFo:has(.download_blockk.is-free-trial) {
  //   @media screen and (max-width:1199px) {
  //     width: 50%;
  //   }
  // }

  .campINFo {
    // width: calc(100% - 30%);

    // @media screen and (max-width:1199px) {
    //   width: inherit;
    // }
    // &:has(.download_blockk.is-free-trial) {
    //   justify-content: flex-end !important;
    //   width: 50%;
    // }
  }
}

// .freeTrialAC {
//   &:has(.download_blockk.is-free-trial) {
//     width: 50% !important;
//   }
// }
// .icpdata.icpDataBlk .is-free-trial ~ .pageTitle {
//   width: 50%; /* Ya apni required width specify karein */
// }

// Take picture

.takePic {
  button {
    &:hover {
      svg {
        path {
          fill: #ffffff
        }
      }
    }
  }
}

// Export icon

.exportsvg {
  width: 17px;
  height: auto;
}

// Update button

.update_icon:not(:disabled) svg path {
  fill: white;
  /* White for enabled state */
}

.buttonGp {
  .downloadbtn {
    @media screen and (max-width:380px) {
      padding: 5px !important;
      height: 90px;
    }
  }
}

// Load Button
.Loading_btn {
  min-width: 12.5rem;
}

.table_block_padd {
  td {
    // padding: inherit !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

// table for my download list

// .mydownload {
//   table {
//     thead {
//       display: table;
//       width: 100%;
//       table-layout: fixed;
//     }
//     tbody {
//       display: block;
//       tr {
//         display: table;
//             width: 100%;
//             table-layout: fixed;
//       }
//     }
//   }
// }

// background img for result

.freecontentWrapper {
  &.FindProspects {
    .bgTable {
      background-repeat: no-repeat;

      @media screen and (max-width:1280px) {
        background-size: cover !important;
      }
    }
  }
}