//color-variables

$black: #000000;
$white: #ffffff;
$primary: #E55426;
$primary1: #F0675C;
$primary2: #FCEEE9;
$offprimary: #FEF5EC;
$secondary: #414141;
$grey: #B3B3B3;
$darkGrey: #7F7D83;
$lightGrey: #f1f1f1;
$midGrey: #7A7A7A;
$neutralGrey: #E6E6E6;
$hintColor: #A0A0A0;
$orange: #F5A243;
$orange1: #F59D40;
$orange3: #F4924A;
$offOrange: rgba(254, 245, 236, 0.90);
$red: #FF2929;
$green: #3AB37E;
$offgreen: #ECFDF3;
$darkgreen: #037847;
$blue: #0077B5;
$lighBlue: #F6F7F9;
$offWhite: #F6F7F9;
$offblack: rgba(65, 65, 65, 0.70);
$offgrey: rgba(65, 65, 65, 0.20);
$offgrey1: rgba(65, 65, 65, 0.50);
$offgrey2: rgba(65, 65, 65, 0.60);
$offgrey3: rgba(65, 65, 65, 0.40);
$offgrey4: rgba(65, 65, 65, 0.80);
$offblue: rgba(236, 236, 236, 0.20);
$circle: #ECECEC;
$grdient: linear-gradient(90deg, #F0675C 0%, #F5A243 100%);
$borderColor: #E5E5E5;
$borderColor1: #ECECEC;
$borderColor2: rgba(236, 236, 236, 0.50);
$borderColor7: rgba(236, 236, 236, 0.20);
$borderColor8: rgba(236, 236, 236, 0.30);
$borderColor3: rgba(245, 157, 64, 0.20);
$borderColor4: rgba(229, 229, 229, 0.28);
$borderColor5: #EAECF0;
$borderColor6: #D0D5DD;
$greyBackground: #FAFAFA;
$greyBackground1: #EAEAEA;
$greyBackground2: #E5E5E5;
$lightGreyBackground: #FBFBFB;
$lightGreyBackground1: #FCFCFD;
$lightGreyBackground2: rgba(229, 84, 38, 0.18);
$normalGreen: #55bf91;


//font-variables

$fontPoppins: 'Poppins', sans-serif;
$fontInter: 'Inter', sans-serif;

//Images url

$assetPath: "/assets/images";

//Screen size

$screen-xs: 576px;
$screen-xxsm: 643px;
$screen-sm: 767px;
$screen-md: 991px;
$screen-lg: 1199px;
$screen-xlg: 1399px;
$screen-xxlg: 1537px;