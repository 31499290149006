@import '../../assets/css/variable.scss';
@import '../../assets/css/mixins.scss';

.buildYourICP {
    .icpForm {
        background: $white;
        border-radius: 1rem;
        padding: 4.6rem 7.8rem;
        height: 100%;

        @media screen and (max-width: $screen-lg) {
            padding: 3.2rem 2.4rem;
        }

        .formSubmit .btn {
            width: 24.1rem;
            margin: 4.4rem auto 0;
            @media screen and (max-width: $screen-md) {
                max-width: fit-content;
                height: fit-content;
            }
        }

        .icpNote {
            font-style: italic;
            font-size: 1.16rem;
            line-height: 1.8rem;
            color: $offgrey4;
            margin: 0.8rem 0 0;

            strong {
                font-weight: 500;
            }
        }

        @include upload-document;



    }


}


.menu-plus {
    width: 0.5px;
    height: 0.5px;
    border: none;
    color: black;
    background-color: white;
}

.error-input {
    border-bottom: 1px solid red;
    /* Set border color to red when there's an error */
}

.box {
    border: 1px dotted red;
    border-radius: 10px;
    /* Set border color to red when there's an error */
}

// .list-box {
//     max-height: 250px !important;
//     height: 250px !important;

//     @media screen and (max-width: $screen-lg) {
//         width: 100%;
//     }
// }

.search-box {
    width: 100% !important;

    @media screen and (max-width: $screen-lg) {
        max-width: 100% !important;
    }
}

.search-icon {
    cursor: pointer;
}

.list-box-item {
    font-family: "poppins";
    font-size: 10px;
    font-weight: 500;
    height: 300px;
    max-height: 300px;
    overflow-y: auto;
    border-radius: 5px;
    margin-top: 15px;

    ul {
        padding-left: 0px;
        max-height: 300px;
        overflow-y: auto;
    }
}

.err-msg {
    margin-top: 10px;
    font-size: 15px;

    @media screen and (max-width: $screen-xs) {
        margin-top: 10px;
    }
}


.err {
    color: red;
}

.green {
    color: green;
}

.pageHeadingBlock.commonHeading .pageTitle {
    @media screen and (max-width: $screen-xs) {
        // width: 130px;
    }

    h3 {
        @media screen and (max-width: $screen-xs) {
            font-size: 1.5px;
            ;
        }

    }
}

.bombara-logo {
    margin-left: 10px;
    margin-bottom: 15px;

    img {
        width: 30%
    }
    @media screen and (max-width: $screen-xs) {
        img {
            width: 60%
        }
    }
    @media screen and (min-width: $screen-sm) and (max-width: $screen-md)  {
        img {
            width: 50%
        }
    }
}

.intent-signal {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2px 8px 2px 10px;
    border-radius: 1.6rem;
    color: $darkgreen;
    /* Define the color variable or replace with an actual color */
    width: fit-content;

    &::before {
        background: $darkgreen;
        /* Define the color variable or replace with an actual color */
    }

    &.strong {
        background: $offgreen;
        /* Define the color variable or replace with an actual color */

        &::before {
            background: $darkgreen;
            /* Define the color variable or replace with an actual color */
        }
          
        .custom-td &:hover {
            text-decoration: underline; /* Add underline on hover */
            text-underline-offset: 5px;
            text-decoration-color: black;
          }
    }

    &.very-strong {
        background: $normalGreen;
        /* Define the color variable or replace with an actual color */
        color: white;

        &::before {
            background: $normalGreen;
            /* Define the color variable or replace with an actual color */
        }

        .custom-td &:hover {
            text-decoration: underline; /* Add underline on hover */
            text-underline-offset: 5px;
            text-decoration-color: black;
          }
    }

    &.super-strong {
        background: $darkgreen;
        /* Define the color variable or replace with an actual color */
        color: white;

        &::before {
            background: $darkgreen;
            /* Define the color variable or replace with an actual color */
        }
        .custom-td &:hover {
            text-decoration: underline; /* Add underline on hover */
            text-underline-offset: 5px;
            color: antiquewhite;
          }
    }
}

.intent-signal-score {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2px 8px 2px 10px;
    border-radius: 1.6rem;
    color: $darkgreen;
    /* Define the color variable or replace with an actual color */
    width: fit-content;

    &::before {
        background: $darkgreen;
        /* Define the color variable or replace with an actual color */
    }

    &.strong {
        // background: $offgreen;
        /* Define the color variable or replace with an actual color */
        background-color: rgba(254, 245, 236, 0.9);
        color: #F59D40;

        &::before {
            background: $darkgreen;
            /* Define the color variable or replace with an actual color */
        }
          
    }

    &.very-strong {
        // background: $normalGreen;
        /* Define the color variable or replace with an actual color */
        // color: white;
        background: #ECFDF3;
        color: #037847;
            

        &::before {
            background: $normalGreen;
            /* Define the color variable or replace with an actual color */
        }
    }

    &.super-strong {
        // background: $darkgreen;
        /* Define the color variable or replace with an actual color */
        // color: white;
        background: #ECFDF3;
    color: #037847;

        &::before {
            background: $darkgreen;
            /* Define the color variable or replace with an actual color */
        }
    }
}
.modal-intent {
    .modal-header{

        padding: 0px;
    }
    h5{
        @media screen and (max-width: $screen-xs) {
            font-size: 15px !important;
        }
    }
    .intent-detailed {
        text-align: left;
        display: flex;
        justify-content: space-between;
        .intent-com {
            display: block;
        }

        h3 {
            // display: flex;
            margin-bottom: 0px !important;
            justify-content: left;
            font-size: 15px;
            color: #E55426;
            span{
             font-size: 12px;
             text-align: end;
             color: $offgrey4;
            }
            @media screen and (max-width: $screen-xs) {
                font-size: 13px !important;
            }
        }

    }

    a,
    span.link {
        color: rgb(103, 156, 222);
    }
    a,
    span.link &:last-child {
        margin-left: 10px;
    }
    a:visited,
    span.visited {
        color: rgb(103, 156, 222);
    }

    a:active,
    span.active {
        color: red;
    }
    .topics-lebel{
        label{
            font-size: 15px;
            font-weight: 600;
            margin-top: 10px;
        }
    }
}

// Save searches CSS

.recent-searches-button {
    background: #ffffff;
    color: #E55426;
    font-size: 1.3rem !important;
    padding: 8px 10px;
    width: fit-content;
    cursor: pointer;
    height: 30px;
    border-radius: 28px;
    @media screen and (max-width:992px) {
        font-size: 1.2rem !important;
        height: auto;
        padding: 3px 9px;
    }
    @media screen and (max-width:380px) {
        font-size: 1rem !important;
        padding: 3px 6px;
    }
}
  
.recent-searches-button:hover {
    border: 1px solid #eb9074;
    background-color: transparent;
    color: #eb9074;
}

.applyBtnSmall {
    padding: 4px 10px !important; 
    font-size: 12px !important;   
    line-height: 1.5 !important;  
    border-radius: 4px !important;
    height: 3.5rem !important;
    border-radius: 2rem !important;
}

.savetext{
    font-size: 1.4rem !important;
}

.buildVais{
    margin-top: 0 !important;
}

.form-check-input:checked{
    background-color: #e55426 !important;
    border-color: #f1f3f5 !important;
}
.saveFiltersModalBody{
    margin: 0 !important;
}
// Save searches CSS END

.form-group.formSubmit {
    input#form-switch {
        @media screen and (max-width:992px) {
            width: 17px;
            height: 17px;
        }

    }

    input[type=checkbox]::before,
    .form-check input[type=checkbox]::before {
        @media screen and (max-width:992px) {
            width: 16px;
            height: 16px;
        }

    }

    input[type=checkbox]:checked::after,
    .form-check input[type=checkbox]:checked::after {
        display: none;
    }
}