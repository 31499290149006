@import "../../assets/css/variable.scss";
@import "../../assets/css/mixins.scss";

.header {
    background: $white;
    position: fixed !important;
    width: 100%;
    z-index: 12;

    // top: 0;
    .navbar-brand {
        padding: 0;

        img {
            width: auto;
            max-height: 3rem;

            @media screen and (max-width: $screen-sm) {
                max-height: 2.8rem;
            }

            @media (min-width: 992px) and (max-width: $screen-lg) {
                max-height: 2.8rem;
            }
        }
    }

    .navbar {
        width: 100%;
        // padding: 2rem 0 1.2rem;
        justify-content: space-between;

        @media screen and (max-width: $screen-md) {
            // padding-top: 1.2rem;
            padding-right: 5rem;
            position: unset;
            padding-left: 10px;
        }
        @media screen and (max-width: 330px) {
            // padding-top: 1.2rem;
            padding-right: 4rem;
            position: unset;
            padding-left: 5px;
        }

        .navbar-collapse {

            // flex-grow: unset;
            @media screen and (max-width: $screen-md) {
                position: absolute;
                background: $white;
                width: 100%;
                top: 6.2rem;
                left: 0;
                padding: 2rem 0;
                border-top: 1px solid $borderColor;
                border-bottom-left-radius: 1rem;
                border-bottom-right-radius: 1rem;
                box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.1);
                z-index: 99;
            }
        }

        .nav-item {
            margin: 0 0.6rem;

            @media screen and (max-width: $screen-md) {
                margin: 0 1.6rem 0.6rem;
            }

            @media screen and (max-width: 1270px) {
                margin: 0 0.4rem;
            }

            .nav-link {
                @include flex-align-center;
                font-size: 1.2rem;
                line-height: normal;
                color: $secondary;
                border: 1px solid $white;
                border-radius: 0.8rem;
                padding: 1.1rem 1.1rem;
                font-weight: 500;

                @media screen and (max-width: 1450px) {
                    padding: 7px;
                    // font-size: 1rem;
                }

                @media screen and (max-width: 1270px) {
                    padding: 1.5px;
                    font-size: 9px;
                }

                @media (min-width: 1200px) and (max-width: 1269px) {
                    padding: 1rem;
                }

                @media (min-width: 992px) and (max-width: $screen-lg) {
                    // font-size: 1.3rem;
                    // padding: 1rem;
                }

                svg {
                    margin-right: 0.5rem;

                    @media screen and (max-width: 1450px) {
                        width: 1.5rem;
                    }

                    @media (min-width: 992px) and (max-width: $screen-lg) {
                        width: 1.8rem;
                        margin-right: 0.25rem;
                    }
                }

                @media screen and (max-width: $screen-md) {
                    display: inline-flex;
                }

                .disabled-link {
                    cursor: not-allowed;
                    color: #aaa;
                    text-decoration: none;
                }

                .disabled-link:hover {
                    color: #aaa;
                }

            }

            @media screen and (max-width: $screen-md) {
                &.active {
                    background: $primary;
                    border: none;
                    color: $offWhite;

                    svg path {
                        fill: $primary;
                    }

                    .nav-link {
                        background: $primary;
                        border: none;
                        color: $offWhite;

                        svg path {
                            fill: $primary;
                        }
                    }
                }
            }

            &.active {
                background: $offWhite;
                border-color: $primary;
                color: $primary;

                svg path {
                    fill: $primary;
                }

                .nav-link {
                    background: $offWhite;
                    border-color: $primary;
                    color: $primary;

                    svg path {
                        fill: $primary;
                    }
                }
            }
        }

        .nav-item {
            position: relative;
            display: inline-block;
        }

        .nav-item .tooltip-text {
            visibility: hidden;
            width: 160px;
            /* Adjusted width for longer text */
            background-color: #555;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 10px;
            position: absolute;
            z-index: 1;
            top: 100%;
            /* Position the tooltip below the link */
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            transition: opacity 0.3s;
        }

        .nav-item .tooltip-text::after {
            content: "";
            position: absolute;
            bottom: 100%;
            /* Arrow at the top of the tooltip */
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent #555 transparent;
        }

        .nav-item:hover .tooltip-text {
            visibility: visible;
            opacity: 1;
        }

        .nav-link {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #414141;
        }

        .nav-link svg {
            margin-right: 8px;
        }

        .disabled-link {
            cursor: not-allowed;
            color: #aaa;
            pointer-events: none;
            /* Disable pointer events on disabled link */
        }

        .disabled-link:hover {
            color: #aaa;
        }


    }

    .notificationDropdown {
        .notifcations {
            cursor: pointer;

            // svg {
            //     @media screen and (max-width: $screen-lg) {
            //         width: 3.2rem;
            //         height: 3.2rem;
            //     }
            // }
        }

        .dropdown-menu {
            top: 6.2rem;
            left: auto;
            right: 0;
            border: none;
            border-radius: 1rem;
            box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.10);
            min-width: 44.0rem;
            padding: 0;
            margin: 0;

            @media (min-width: 992px) and (max-width: $screen-lg) {
                top: 5.2rem;
            }

            @media (min-width: 577px) and (max-width: $screen-md) {
                top: 4.8rem;
            }

            @media screen and (max-width: $screen-xs) {
                top: 4.8rem;
                min-width: 27rem;
                right: auto;
                left: -50%;
                transform: translateX(-50%);
            }
            .noti-Mob {
                display: none;
                @media screen and (max-width:$screen-xs) {
                    display: block;
                }
            }
            .noti-Desk {
                @media screen and (max-width:$screen-xs) {
                    display: none;
                }
            }
            .text-Mob {
                @media screen and (max-width:$screen-xs) {
                    flex-grow: 1;
                    padding: 0;
                }
            }

            .notificationHeading {
                @include flex-space-between;
                padding: 1.6rem 2.4rem;
                border-bottom: 1px solid rgba(65, 65, 65, 0.20);

                @media screen and (max-width: $screen-lg) {
                    padding: 1.6rem;
                }

                * {
                    margin: 0;
                }

                .markRead {
                    cursor: pointer;
                    color: $primary;
                    font-size: 1.4rem;

                    &:hover {
                        color: #c23206;
                    }
                }
            }

            .notificationList {
                max-height: 39.8rem;
                overflow-y: auto;

                @media screen and (max-width: $screen-xs) {
                    max-height: 24rem;
                }

                .listItem {
                    position: relative;
                    padding: 1.4rem 2.4rem;
                    border-bottom: 1px solid $borderColor;
                    // .listBLK {
                    //     gap: 12px;
                    // }

                    @media screen and (max-width: $screen-lg) {
                        // padding-left: 5.8rem;
                        padding: 1.4rem 1.6rem;
                    }

                    &:last-child {
                        border-bottom: none;
                        border-bottom-left-radius: 1rem;
                        border-bottom-right-radius: 1rem;
                    }

                    .icon {
                        position: absolute;
                        left: 2.4rem;
                        top: 1.4rem;

                        @media screen and (max-width: $screen-lg) {
                            left: 1.6rem;
                        }
                        // Notification issues
                        &.iconBlk {
                            position: inherit;
                            left: inherit;
                            top: inherit;
                            @media screen and (max-width: $screen-lg) {
                                left: inherit;
                            }
                            svg {
                                width: 4rem;
                                height: 4rem;
    
                                @media screen and (max-width: $screen-lg) {
                                    width: 3rem;
                                    height: 3rem;
                                }
                            }
                        }

                        svg {
                            width: 4rem;
                            height: 4rem;

                            @media screen and (max-width: $screen-lg) {
                                width: 3rem;
                                height: 3rem;
                            }
                        }
                    }

                    .notificationTime {
                        position: absolute;
                        right: 2.4rem;
                        top: 0.4rem;
                        font-size: 1.2rem;
                        line-height: normal;
                        color: $offgrey1;

                        &.notifyBLK {
                            position: inherit;
                            right: inherit;
                            top: inherit;
                            @media screen and (max-width: $screen-lg) {
                                right: inherit;
                            }
                        }

                        @media screen and (max-width: $screen-lg) {
                            right: 1.6rem;
                        }
                    }

                    h6 {
                        margin: 0 0 0.4rem;
                    }

                    p {
                        font-size: 1.4rem;
                        line-height: 2rem;
                        margin: 0;

                        @media screen and (max-width: $screen-xs) {
                            font-size: 1.2rem;
                            line-height: 1.8rem;
                        }
                    }

                    &.reminderNotification,
                    &.serverNotification {
                        background: $greyBackground;
                    }
                }
            }
        }
    }

    .userProfileDropdown {
        .profileDropdown {
            @include flex-align-center;
            cursor: pointer;
            margin-left: 1.2rem;

            @media screen and (max-width: $screen-sm) {
                margin-left: 0.5rem;
            }

            @media screen and (max-width: 360px) {
                margin-left: 0.1rem;
            }

            .userImage {
                width: 2.8rem;
                height: 2.8rem;
                border-radius: 100%;

                @media screen and (max-width: $screen-lg) {
                    // width: 3.8rem;
                    // height: 3.8rem;
                }
            }

            &.show {
                .chevronDown {
                    transform: rotate(180deg);
                }
            }

            // .chevronDown {
            //     svg {
            //         @media screen and (max-width: $screen-lg) {
            //             width: 1.6rem;
            //             height: 1.6rem;
            //         }
            //     }
            // }
        }

        .dropdown-menu {
            top: 6.2rem;
            left: auto;
            right: 0;
            border: none;
            border-radius: 1rem;
            box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.10);
            min-width: 19.8rem;
            padding: 0;
            margin: 0;

            @media (min-width: 992px) and (max-width: $screen-lg) {
                top: 5.2rem;
            }

            @media screen and (max-width: $screen-md) {
                top: 5.1rem;
            }

            ul {
                padding: 0;
                margin: 0;
                list-style: none;

                li {
                    position: relative;
                    padding: 1.2rem 1.2rem 1.2rem 4.4rem;
                    border-bottom: 1px solid $greyBackground2;
                    margin: 0;

                    &:last-child {
                        border-bottom: none;
                    }

                    &.active {
                        border-color: $primary;
                        color: $primary;

                        svg path {
                            fill: $primary;
                        }

                        a {
                            border-color: $primary;
                            color: $primary;

                            svg path {
                                fill: $primary;
                            }
                        }
                    }

                    a {
                        @include flex-align-center;
                        color: $secondary;
                        font-size: 1.4rem;
                        line-height: 2rem;

                        svg {
                            width: 2rem;
                            height: 2rem;
                            position: absolute;
                            left: 1.8rem;
                            top: 1.2rem;

                            path {
                                fill: $black;
                            }
                        }

                        &:hover,
                        &:focus {
                            color: $primary;

                            svg {
                                path {
                                    fill: $primary;
                                }
                            }
                        }
                    }

                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
    }

    button.navbar-toggler {
            width: auto;
            position: absolute;
            top: 1.6rem;
            right: 1.6rem;
            width: 3rem;
            height: 3rem;
        background: $primary;
        border-color: $primary;

        // @media screen and (min-width: 768px) {
        //     top: 1.3rem;
        // }

        @media screen and (max-width: 360px) {
            width: 25px;
            height: 25px;
            top: 1.8rem;
        }
        
        @media screen and (max-width: 360px) {
            right: 1.0rem;
        }

        .navbar-toggler-icon {
            color: $white;
        }
    }
}

.userInitialMobile {
    @media screen and (max-width: 360px) {
        width: 25px !important;
        height: 25px !important;
        font-size: 14px;
    }
}

.dropdown {
    &.notificationDropdown {
        .notifcations {
            div {
                div {
                    div {
                        right: 6px !important;
                        font-size: 10px !important;
                        border-radius: 50% !important;
                        width: 18px;
                        height: 18px;
                        display: flex !important;
                        align-items: center !important;
                        justify-content: center !important;
                        @media screen and (max-width: $screen-lg) {
                            border-radius: 50%;
                            width: 15px;
                            height: 15px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}

.showG2ModalReview {
    .modal-body {
        max-width: 100% !important;
        padding: 0;
        margin: auto;
    }
}

.dropdown {
    &.notificationDropdown {
        .notifcations {
            div {
                div {
                    svg {
                        @media screen and (max-width:1280px) {
                            width: 43px;
                            height: auto;
                        }
                        @media screen and (max-width:360px) {
                            width: 41px;
                            height: 41px;
                        }
                    }
                }
            }
        }
    }

   
}
.userProfileDropdown {
    .profileDropdown {
        .userNameLetter.userInitialMobile {
            // @media screen and (max-width:1280px) {
            //     width: 3.2rem !important;
            //     height: 3.2rem !important;
            //     font-size: 21px;
            // }
            @media screen and (max-width:360px) {
                width: 25px !important;
                height: 25px !important;
                font-size: 16px;
            }
        }

        .chevronDown {
            svg {
                    width: 18px;
                    height: 18px;
                @media screen and (max-width:360px) {
                    width: 13px;
                    height: 13px;
                }
            }
        }
    }
}
.G2-logoBlk {
    @media screen and (max-width:360px) {
        width: 35px !important;
    }
}