@import '../../assets/css/variable.scss';
@import '../../assets/css/mixins.scss';


/* Base styles for the entire component */
.pageHeadingBlock {
  margin-bottom: 30px;
}

.pageContentBlock {
  padding: 20px;
}


table td,
.table td {
  // white-space: nowrap !important;
}

/* Table Heading Block */
.tableHeadingBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  // display: flex;
  .leftContent {
    .searchForm {
      display: flex;
      align-items: center;

      .form-group {
        position: relative;

        input.form-control {
          padding-right: 40px;
          border-radius: 30px;
          border: 1px solid #e0e0e0;
          font-size: 14px;
          transition: border-color 0.3s;

          &:focus {
            border-color: rgb(244, 146, 74);
          }
        }

        button {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          background: none;
          border: none;
          cursor: pointer;
          padding: 0;

          svg {
            fill: #414141;
          }

          &:hover svg {
            fill: rgb(244, 146, 74);
          }
        }
      }
    }
  }

  .raiseTicket {
    .btn {
      background-color: rgb(243, 243, 243);
      color: #E55426;
      padding: 10px 20px;
      font-size: 14px;
      border-radius: 25px;
      // text-transform: uppercase;
      box-shadow: 1px solid #E55426;
      transition: background-color 0.3s, box-shadow 0.3s;
      width: fit-content;
      height: auto;

      &:hover {
        background-color: #E55426;
        color: #fff;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
      }
    }
  }
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th,
  td {
    padding: 12px;
    text-align: left;
    font-size: 14px;
    border-bottom: 1px solid #f1f1f1;
  }

  th {
    background-color: #f8f8f8;
    color: #555;
    font-weight: bold;
  }

  tbody tr {
    // transition: background-color 0.3s;
  }

  tbody tr:hover {
    // background-color: #f5f5f5;
  }

  table tbody tr:hover {
    background-color: #f5f5f5 !important;
  }

  td.status {
    text-transform: capitalize;
  }

  td i {
    font-size: 18px;
    color: #f44336;
    cursor: pointer;
    transition: color 0.3s;

    &.fa.fa-circle {
      color: inherit;
      font-size: 6px;
    margin-right: 9px;
    }

    &:hover {
      color: #d32f2f;
    }
  }

  td.text-center {
    text-align: center;
  }
}

/* Add responsive design for smaller screens */
@media (max-width: 768px) {
  .tableHeadingBlock {
    flex-direction: column;
    // align-items: flex-start;
    display: flex;
    align-items: center;
    justify-content: center;

    .raiseTicket {
      margin-top: 20px;
    }
  }

  table {
    font-size: 12px;
  }

  th,
  td {
    padding: 10px;
  }

  .form-group input.form-control {
    width: 100%;
  }
}

.status-signal-score {
  position: relative;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // text-align: center;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 1.6rem;
  color: $darkgreen;
  white-space: nowrap;
  /* Define the color variable or replace with an actual color */
  // width: 100px; //commented

  &::before {
    background: $darkgreen;
    /* Define the color variable or replace with an actual color */
  }

  &.complete {
    color: #F59D40;
    background-color: rgba(254, 245, 236, 0.9);
    // color: #037847;
    // background: #ecfdf3;
    /* Define the color variable or replace with an actual color */

    &::before {
      background: #ebb810;
      /* Define the color variable or replace with an actual color */
    }

    .custom-td &:hover {
      text-decoration: underline;
      /* Add underline on hover */
      text-underline-offset: 5px;
      text-decoration-color: black;
    }
  }

  &.in_progress {
    // background: $normalGreen;
    /* Define the color variable or replace with an actual color */
    // color: rgb(7, 54, 23);
    color: #F59D40;
    background: rgba(254, 245, 236, 0.9);

    &::before {
      background: $normalGreen;
      /* Define the color variable or replace with an actual color */
    }

    .custom-td &:hover {
      text-decoration: underline;
      /* Add underline on hover */
      text-underline-offset: 5px;
      text-decoration-color: black;
    }
  }

  &.open {
    // background: #ce5a17;
    /* Define the color variable or replace with an actual color */
    // color: white;
    color: #F59D40;
    background: rgba(254, 245, 236, 0.9);

    &::before {
      background: #ce5a17;
      /* Define the color variable or replace with an actual color */
    }

    .custom-td &:hover {
      text-decoration: underline;
      /* Add underline on hover */
      text-underline-offset: 5px;
      color: antiquewhite;
    }
  }

  &.Accepted {
    // background: #d48e0b;
    /* Define the color variable or replace with an actual color */
    // color: white;

    color: #037847;
    background: rgba(3, 120, 71, 0.1);

    &::before {
      background: #d48e0b;
      /* Define the color variable or replace with an actual color */
    }

    .custom-td &:hover {
      text-decoration: underline;
      /* Add underline on hover */
      text-underline-offset: 5px;
      color: antiquewhite;
    }
  }
}

.icon-wrapper-campaign {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 27px;
  border-radius: 2rem;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s;
  border: 1px solid #E55426;
  color: #E55426;
}

.icon-wrapper-campaign:hover {
  background-color: #E55426;
  color: white !important;
  // border: 1px solid #3f3e3e;
  /* Change to your preferred hover color */
}

.icon-wrapper-campaign:last-child {
  margin-right: 0;
  /* Remove margin for the last icon */
}

//added

.Table-Heading {
  text-align: center;
}

.pageHeadingBlock .icpDataList li .Webinar-circle {
  background: radial-gradient(66.83% 50% at 50% 50%, #008ef6 3.79%, #008ef6 100%) !important;
  filter: drop-shadow(0px 0px 10.111px rgba(255, 255, 255, 0.2)) drop-shadow(0px 0px 10.111px rgba(20, 186, 109, 0.5)) !important;
  width: 1rem !important;
  height: 1rem !important;
  border-radius: 2rem !important;
}

.req-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4rem;
  padding: 1.5rem;
  background: #E55426;
  border: 1px solid #E55426;
  border-radius: 10rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: #ffffff;
  outline: none;
  box-shadow: none;
  text-align: center;
}

.req-btn:hover {
  border: 1px solid #000;
  color: #fff;
}

.myDownloadBLKK table tbody tr:hover td:first-child::before, .myDownloadBLKK table tbody tr:focus td:first-child::before {
  content: "";
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 3px;
  height: 100%;
  background: #F5A243;
}
