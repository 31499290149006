@import '../../../assets/css/variable.scss';
@import '../../../assets/css/mixins.scss';

.ICPScore {
    @include card-box;

    // padding: 5.6rem 8rem;
    @media screen and (max-width: $screen-lg) {
        padding: 5.6rem;
    }

    @media screen and (max-width: $screen-sm) {
        padding: 3.2rem 2.4rem;
    }

    .row {
        margin: 0 -2.7rem;

        .col-12 {
            padding: 0 2.7rem;

            @media screen and (max-width: $screen-md) {
                margin: 0;
            }
        }
    }

    .ICPScoreGraphContent {
       
        @media screen and (max-width: $screen-md) {
            margin-top: 4rem;
        }

        h4 {
            font-size: 1.5rem;
            line-height: 2.6rem;
            font-weight: 500;
            margin-bottom: 2.4rem;
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: $screen-xs) {
               flex-direction: column;
               justify-content: center;
            }
    
            span {
                font-size: 1.5rem;
                line-height: 2.6rem;
                font-weight: 500;
                @media screen and (max-width: $screen-xs) {
                    font-size: 13px;
                 }
            }
        }

        p {
            font-size: 1.5rem;
            line-height: 3rem;
            margin: 0;

            @media screen and (max-width: $screen-sm) {
                font-size: 1.6rem;
                line-height: 2.4rem;
            }
        }

        .btn {
            max-width: 16.2rem;
            margin: 4.2rem 0 0;
        }
    }
}

.tooltip-content {
    padding: 5px;

    span strong {
        color: red
    }
}

.highlight-text{
    background: linear-gradient(90deg, #e45324, #fa9b41, #f3785a);
    background-size: 200% 200%;
    animation: gradientHighlight 2s linear infinite;
    -webkit-background-clip: text;
    color: transparent;
    text-shadow: 1px .5px 1px #fff;
    // z-index : "9999 !important";
    // margin: "auto !important"; 
    // text-align : "center";
    // color: "white";
    // position: "fixed";
    // top: "30%";
    // left: "50%";
    // transform: "translateX(-50%)",
  }
