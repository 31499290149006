@import '../../assets/css/variable.scss';
@import '../../assets/css/mixins.scss';

.pageHeadingBlock {
    .btn.btnPrimaryOutline {
        background: $lighBlue;
        font-size: 1.2rem;
        line-height: 1.8rem;
        padding: 0.8rem 2.2rem;
        height: auto;

        @media screen and (max-width: $screen-md) {
            margin: 0.5rem 0;
        }
    }
}

.subscription-pageHeader {
    margin: 0;
    align-items: center;
}

.planSubscritions {
    .col-12 {
        margin-bottom: 2.4rem;
    }

    .planCard {

        display: flex;
        flex-direction: column;
        @include card-box;
        padding: 0;
        height: 100%;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 4px, rgba(0, 0, 0, 0.3) 0px 0px .5px;

        .planBillingDetails {
            padding: 3.4rem 1.9rem 2.4rem;
            border-bottom: 1px solid $primary;
            margin: 0 3rem;

            @media (min-width: $screen-md) and (max-width: $screen-lg) {
                padding: 3.4rem 1rem 2.4rem;
            }
        }

        // .plans-btn-block{
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: space-between;
        // }
        .planName {
            margin: 0 0 .6rem;

            @media (min-width: $screen-md) and (max-width: $screen-lg) {
                margin: 0 0 1.6rem;
            }

            h3 {
                color: $primary1;
                line-height: 4.2rem;
                margin: 0;
                font-size: 2.5rem;
            }

            p {
                font-size: 1.2rem;
                line-height: 2.6rem;
                margin: 0;

                @media (min-width: $screen-md) and (max-width: $screen-lg) {
                    font-size: 1.1rem;
                    line-height: 1.6rem;
                }
            }
        }

        .planPrice {
            @include flex-justify-center;

            sup {
                font-size: 1.4rem;
                font-weight: 500;
                line-height: 20px;
                color: $primary;
                margin-right: 0.2rem;
                margin-top: -1rem;
            }

            h2 {
                color: $primary;
                font-size: 4rem;
                line-height: normal;
                font-weight: 600;
                margin: 0;

                @media (min-width: $screen-md) and (max-width: $screen-lg) {
                    font-size: 3.2rem;
                }

                span {
                    display: inline-block;
                    max-width: 5rem;
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                    font-weight: 300;
                    text-align: left;
                    color: $offblack;
                }
            }
        }

        .planFeatures {
            padding: 3.2rem 1.9rem 1.9rem;
            flex-grow: 1;

            p {
                font-size: 1.4rem;
                line-height: normal;
                font-weight: 500;
            }

            ul {
                padding: 0;
                margin: 0;
                list-style: none;

                li {
                    position: relative;
                    font-size: 1.2rem;
                    padding-left: 3.6rem;
                    margin: 0 0 0.8rem;

                    @media (min-width: $screen-md) and (max-width: $screen-lg) {
                        line-height: 2rem;
                    }

                    &:last-child {
                        margin: 0;
                    }

                    &.yesPlans::before {
                        @include before-after;
                        left: 0;
                        top: 0;
                        background: url(../../assets/images/greenTick.png);
                        background-size: contain;
                        width: 2.4rem;
                        height: 2.4rem;
                    }

                    &.noPlans::before {
                        @include before-after;
                        left: 0;
                        margin-left: 3px;
                        top: 0;
                        background: url(../../assets/images/cross.png);
                        background-size: contain;
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                }
            }
        }

        .getStarted {

            // padding: 0 1.9rem 2.9rem;
            .btn {
                font-size: 1.2rem;
                line-height: 1.8rem;
                font-weight: 600;
                padding: 0.8rem 1.6rem;
                max-width: 14.3rem;
                height: auto;
                margin: auto;
            }
        }
    }
}

.getStarted.btn {
    max-width: 18.5rem;
    margin: 0 auto 20px;
    /* This centers the element horizontally and adds 20px margin from the bottom */
}

// .note p {
//     color: #808080; /* Gray color */
// }

.orderSummary {
    position: relative;
    height: 100%;
    padding-bottom: 8rem;

    .choosedPlan,
    .additionalCredits {
        @include flex-space-between;
        // background: rgba(229, 84, 38, 0.05);
        border: 1px solid rgba(229, 84, 38, 0.20);
        border-radius: 0.8rem;
        padding: 1.6rem 2.4rem;
        margin-bottom: 0.8rem;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 4px, rgba(0, 0, 0, 0.3) 0px 0px .5px;

        .planName {
            font-weight: 500;
        }

        .planName,
        .addCredits {
            @include flex-align-center;

            // color: $primary;
            input {
                margin-right: 1.2rem;

                &:checked {
                    &::before {
                        background: $primary;
                    }

                    &::after {
                        border-color: $white;
                    }
                }
            }
        }

        .btn {
            width: auto;
            height: auto;
            background: transparent;
            font-size: 1.2rem;
            line-height: normal;
            font-weight: 500;

            &:hover,
            &:focus {
                background: $secondary;
            }

            @media screen and (max-width: $screen-sm) {
                margin: 0.5rem 0;
                padding: 1rem;
            }
        }
    }

    .additionalCredits {
        background: $white;

        .countCredits {
            @include flex-align-center;
            flex-wrap: nowrap;
            background: $white;
            border-radius: 0.8rem;
            padding: 0.8rem;
            border: 1px solid rgba(250, 221, 212, 0.20);

            @media screen and (max-width: $screen-sm) {
                margin: 0.5rem 0;
            }

            input {
                border: none;
                outline: none;
                box-shadow: none;
                text-align: center;
                max-width: 8rem;
                font-size: 1.4rem;

                &:focus {
                    border: none;
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }

    .divider {
        width: 100%;
        height: 1px;
        background: $offgrey;
        margin: 2.5rem 0;
    }
}

.pageHeadingBlock {
    @media screen and (max-width: $screen-xxsm) {
        justify-content: center !important;
    }

    &.subscripBlock {
        @media screen and (max-width: 830px) {
            justify-content: center !important;
        }
    }

    &.subscripBlock_two {
        @media screen and (max-width: 991px) {
            justify-content: center !important;
        }
    }
}