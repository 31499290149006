@import '../../../assets/css/variable.scss';
@import '../../../assets/css/mixins.scss'; 

.abmResults{
    @include card-box;
    padding: 0;
}
.disabled {
    pointer-events: none; /* Prevents clicks */
    opacity: 0.5; /* Makes it visually inactive */
  }
 