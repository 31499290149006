@import '../../../assets/css/variable.scss';
@import '../../../assets/css/mixins.scss';

.SingleSupportTicket {
    @include card-box;
    padding: 4rem 9rem 2.4rem;

    @media screen and (max-width: $screen-sm) {
        padding: 4rem 2.4rem 2.4rem;
    }

    .details {
        @include flex-align-center;
        margin: 0 0 2rem;

        h5 {
            font-size: 2rem;
            line-height: 2.4rem;
            font-weight: 600;
            margin: 0 2rem 0 0;
        }

        h3 {
            font-size: 2rem;
            line-height: 2.4rem;

            margin: 0 2rem 0 0;
        }

        .ticketLabels {
            .labelBox {
                border-radius: 1.6rem;
                background: #F5FAFF;
                font-size: 1.2rem;
                line-height: 1.8rem;
                font-weight: 500;
                display: inline-block;
                padding: 0.4rem 1.2rem;
                color: #0070FF;
                margin: 0.4rem 0.4rem 0.4rem 0;
            }
        }
    }

    .uploadedDocs {
        @include flex-align-center;

        img {
            width: 8.2rem;
            height: 6.2rem;
            border-radius: 0.8rem;
            margin: 0 1.6rem 1rem 0;
            border: 1px solid $primary;
        }
    }

}

.ticketDetails ul {
    @include flex-align-center;
    padding: 0;
    margin: 0;
    list-style: none;

    @media screen and (max-width: $screen-sm) {
        margin: 1rem 0 0;
    }

    li {
        @include flex-justify-center;
        background: $white;
        padding: 0.8rem 2rem;
        border-radius: 0.8rem;
        border: 1px solid $white;
        margin: 0 1.2rem 0.6rem 0;
        color: $secondary;

        &:last-child {
            margin-right: 0;
        }

        .circle {
            width: 1.3rem;
            height: 1.3rem;
            border-radius: 100%;
            margin-right: 1.6rem;

            &.inProcess {
                background: radial-gradient(66.83% 50% at 50% 50%, #FFC98A 3.79%, #F5A243 100%);
                filter: drop-shadow(0px 0px 10.111px rgba(255, 255, 255, 0.20)) drop-shadow(0px 0px 10.111px rgba(245, 162, 67, 0.50));
            }

            &.resolved {
                background: radial-gradient(66.83% 50% at 50% 50%, #40C789 3.79%, #14BA6D 100%);
                filter: drop-shadow(0px 0px 10.111px rgba(255, 255, 255, 0.20)) drop-shadow(0px 0px 10.111px rgba(20, 186, 109, 0.50));
            }
        }

        span {
            color: $secondary;

            &.text {
                font-size: 1.4rem;
            }

            &.number {
                font-weight: 600;
            }

            &.boldValue {
                font-weight: 600;
                margin-left: 1.6rem;
            }
        }
    }
}

.SupportTicketComments {
    margin: 3rem 0 0;

    h5 {
        font-size: 2rem;
        line-height: 2.4rem;
        font-weight: 500;
        margin: 0 0 2.4rem;
    }

    .commentForm {
        .userDetails {
            @include flex-align-center;

            img {
                width: 4.8rem;
                height: 4.8rem;
                border-radius: 100%;
                margin: 0 2.4rem 0 0;
            }

            p {
                margin: 0;
                font-weight: 600;
            }
        }

        textarea {
            height: 5rem;
        }

        .btnGroup {
            @include flex-align-center;
            justify-content: flex-end;

            .btn {
                width: auto;
                display: inline-flex;
                min-width: 11.2rem;
                margin-left: 0.8rem;
            }
        }
    }

    .messagesAdded {
        margin: 2.4rem 0 0;

        p {
            margin: 0 0 0.8rem;
        }

        .messageBlock {
            display: flex;
            margin: 0 0 2.4rem;

            .userImage {
                width: 4.8rem;
                min-width: 4.8rem;
                height: 4.8rem;
                border-radius: 100%;
                margin: 0 2.4rem 0 0;

                img {
                    width: 4.8rem;
                    height: 4.8rem;
                    border-radius: 100%;
                }
            }

            .userMessage {
                h6 {
                    @include flex-align-center;
                    font-weight: 600;
                    margin: 0 0 0.6rem;

                    span {
                        font-size: 1.2rem;
                        line-height: 2.4rem;
                        margin-left: 2.4rem;
                        font-weight: 400;

                        @media screen and (max-width: $screen-xs) {
                            margin-left: 0;
                            display: block;
                        }
                    }
                }
                p{
                    word-break:break-all;

                }
            }
        }
    }
}

.fade:not(.show) {
    opacity: 1 !important;
}

.message{
    p{
        word-break: break-all;
    }
}