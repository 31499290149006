@import '../../../../assets/css/variable.scss';
@import '../../../../assets/css/mixins.scss';

.successModal{
    .modal-content{
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .successIcon{
        width: 7.8rem;
        height: 7.8rem;
        object-fit: contain;
        margin: 0 auto 2.4rem;
    }
    p{
        font-weight: 500;
        margin: 0;
    }
    .btn{
        display: inline-flex;
        width: auto;
        min-width: 10.8rem;
        margin-top: 3.2rem;
    }
}
