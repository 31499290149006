@import '../../assets/css/variable.scss';
@import '../../assets/css/mixins.scss';

.SupportTicketsHeading {
    .raiseTicket {
        .btn {
            min-width: 14.5rem;

            @media screen and (max-width: $screen-sm) {
                min-width: auto;
                font-size: 12px;
                line-height: 16px;
                height: 3.8rem;
                padding: 1.2rem 0.8rem;
            }
        }
    }
    @media screen and (max-width: $screen-sm) {
        // flex-direction: column;
    }
}

.SupportTickets {
    @include card-box;
    padding: 0;

    .tableContentBlock {
        table {
            td {
                white-space: normal;
            }
        }
    }
}

.SupportTickets table tbody tr:hover td,
.SupportTickets table tbody tr:focus td {
    background: #FEF5EC;
    border-color: rgba(245, 157, 64, 0.2);


}

.SupportTickets table tbody tr:hover td:first-child::before,
.SupportTickets table tbody tr:focus td:first-child::before {
    content: "";
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 3px;
    height: 100%;
    background: #F5A243;
}
.pageHeadingBlock{
&.supportBlk {
    justify-content: space-between !important;
}
}
.contentWrapper {
    &.SupportTickets {
        .tableHeadingBlock {
            @media screen and (max-width:767px) {
            flex-direction: inherit !important;
            }
            .leftContent {
                @media screen and (max-width:767px) {
                    flex-direction: inherit;
                    align-items: inherit;
                }
        }

        }
    }
}
