
.main-table {
  text-align: center;
  position: relative;
  border-collapse: collapse; 
  background-color: #f6f6f6;
}/* Spacing */
.td, .th {
  // border: 1px solid #999;
  padding: 20px;
  // border:no
}
.th {
  background: #ed8868;
  color: white;
  border-radius: 0;
  position: sticky;
  top: 0;
  padding: 5px 20px !important;
}
.primary{
  font-weight: bold;
  color: #fff;
  background-color: #414141;
  border-top-left-radius: 1rem;
}

tfoot > tr  {
  background: black;
  color: white;
}
.tbody > .tr:hover {
  background: #f8e2da !important;
     color: white;
}
.table-container{
  overflow: 'auto' !important;
  max-height:"100px" !important;
  width: 100% !important;
}
@media (max-width: 480px) {
  .table-container {
    max-height: 300px; /* Reduce max height on small screens */
  padding: 0;
  }
  .customTable {
    width: 100% !important; /* Ensure the table takes the full width on mobile */
  }
}