@import "../../assets/css/variable.scss";
@import "../../assets/css/mixins.scss";

.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
  .crossicon{
    margin-left: 0.8rem;
    cursor: pointer;
    top: 15px;
    font-size:1.7rem;
    font-weight:bold;
  }
  .contentCol{
    line-height: 1.9rem;
  }
  .contentHead{
    font-size: 1.2rem;
  }
  .contentSubHead{
    font-size: 1.2rem;
  }
  .maincontentrow{
    flex-direction: row;
  }
  .leftIcon{
    font-size: 1em;
    color: rgb(220, 57, 19);
    border: 1px solid white;
    border-radius: 50%;
  }
  .MainContent{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    // background: rgb(193 114 114 / 38%);
    border-left: 5px solid #DC3913;
  }