/* PaymentForm.scss */
@import '../../assets/css/variable.scss';
@import '../../assets/css/mixins.scss';

.payment-form {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: $screen-xs) {
      max-width: 100% !important;
      margin: auto !important;
      padding: 0px !important;
      border: none !important;
      border-radius: 0px !important;
      box-shadow: none !important;
    }
  }
  
  .card-element {
    /* Style the CardElement */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    @media screen and (max-width: $screen-xs) {
      // border: none !important;
      padding: 7px !important;
    }
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
   
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Additional styles for better visualization of CardElement */
  .StripeElement {
    width: 100%;
    // padding: 10px;
    // border: 1px solid #ccc;
    // border-radius: 4px;
    @media screen and (max-width: $screen-xs) {
      // border: none !important;
      // padding: 7px !important;
    }
  }
  
  /* Styles for valid and invalid state of CardElement */
  .StripeElement--invalid {
    border-color: #ff3860;
    color: #ff3860;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
  
  