@import '../../../../assets/css/variable.scss';
@import '../../../../assets/css/mixins.scss';

.phoneVerificationModal{
    .successIcon{
        width: 7.8rem;
        height: 7.8rem;
        object-fit: contain;
        margin: 0 auto 2.4rem;
    }
    p{
        font-weight: 500;
        margin: 0;
    }
    .btn{
        width: 100%;
        max-width: 48rem;
        margin: 0 auto;
    }
    .validationFields{
        @include flex-justify-center;
        margin: 4rem 0;
        @media screen and (max-width: $screen-xs) {
            margin: 2rem 0;
        }
        .form-control{
            font-size: 2.6rem;
            max-width: 5.6rem;
            margin: 0 0.5rem;
            text-align: center;
            @media screen and (max-width: $screen-xs) {
                font-size: 2rem;
            }
        }
    }
}
