@import '../../assets/css/variable.scss';
@import '../../assets/css/mixins.scss';


.btnBlock {
    background: #F6F7F9;
    border-color: #E55426;
    color: #E55426;
}

.btnBlock>.active {
    background: #E55426;
    color: #F6F7F9;
}

.tabBtnActive {
    background: #E55426;
    color: #F6F7F9;
}

.detailsOverviewGraph {
    padding: 1.4rem !important;
    margin: 1rem !important;
}

.subBtn {
    max-width: 18.5rem;
    margin: 0 auto 20px;
}


/* resize font-size on html and body level. html is required for widths based on rem */
@media screen and (min-width: 1024px) {

    html,
    body {
        font-size: 24px;
    }
}

@media screen and (max-width: 1024px) {

    html,
    body {
        font-size: 16px;
    }
}

@media screen and (max-width: 600px) {

    html,
    body {
        font-size: 12px;
    }
}

/* a container - decorative, not required */
// .container {
//     border: 1px solid plum;
//     width: 20rem;
//     padding: 1rem;
//     margin-left: auto;
//     margin-right: auto;
//     margin-top: 5%;
// }
/* p - decorative, not required */
// p {
//   margin-top:2rem;
//   font-size:0.75rem;
//   text-align:center;
// }

:root {
    --switches-bg-color: #dd5226db;
    --switches-label-color: white;
    --switch-bg-color: white;
    --switch-text-color: #dd5226db;
}


/* container for all of the switch elements 
    - adjust "width" to fit the content accordingly 
*/
.switches-container {
    width: 18rem;
    position: relative;
    display: flex;
    padding: 0;
    position: relative;
    background: var(--switches-bg-color);
    line-height: 3rem;
    border-radius: 3rem;
    margin-left: auto;
    margin-right: auto;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
    visibility: hidden;
    position: absolute;
    top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container label {
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    color: var(--switches-label-color);
    padding-right: 25px;
}

/* switch highlighters wrapper (sliding left / right) 
    - need wrapper to enable the even margins around the highlight box
*/
.switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    padding: 0.15rem;
    z-index: 3;
    transition: transform .5s cubic-bezier(.77, 0, .175, 1);
    /* transition: transform 1s; */
}

.btnSm {
    min-width: 10.1rem !important;
    margin-right: 50px !important;
}

/* switch box highlighter */
.switch {
    border-radius: 3rem;
    background: var(--switch-bg-color);
    height: 100%;
}

/* switch box labels
    - default setup
    - toggle afterwards based on radio:checked status 
*/
.switch div {
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: var(--switch-text-color);
    transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked~.switch-wrapper {
    transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked~.switch-wrapper {
    transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container input:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1) {
    opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container input:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
    opacity: 1;
}

// .customTable{
//     width: 90% !important;
//     margin-left: 40px !important;


// }

// .customTable thead{
//     border-radius: 50% !important;
// }
.customTable {
    border-radius: 12px;
    /* Add rounded corners */
    overflow: hidden;
    /* Ensures corners are visually rounded */
    border-collapse: separate;
    /* Ensures spacing works */
    // border-spacing: 0 8px;
    /* Adds space between rows */
    width: 90% !important;
    /* Adjust table width */

}

.customTable tbody td {
    border-radius: 20px !important;
}

.rounded {
    border-radius: 20px !important;
}



.tbhead {
    // border-radius: 20px;
    border-radius: 30px 0px 0px 30px;
    background-color: rgb(244, 146, 74) !important;
    /* Force background color */
    color: white;
    /* Optional: change text color for better contrast */
}

.customTable tbody tr {
    border-radius: 20px !important;
    background-color: #d1d1d0f2;
    /* Add row background color */
    transition: transform 0.2s;
    /* Optional: row hover effect */
}


.customTable tbody tr:hover {
    transform: scale(1.01);
    /* Slightly scale on hover */
}

.text-center-camapaign-table {
    text-align: center;
    font-style: italic;
    text-transform: uppercase;
}

.font-bold-camapaign-table {
    font-weight: bold;
}

// input {
//     &.form-check-input:disabled {
//       cursor: not-allowed;
//     }
//   }

#quantity {
    padding: 8px;
    // border: 1px solid #ccc;
    border-radius: 4px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: auto;
        /* Re-enable the spin buttons */
        appearance: auto;
        /* Ensure that spin buttons are displayed in all browsers */
    }
}

//added
.table-width {
    width: 100% !important;
}

@media screen and (max-width: 480px) {
    .table-width {
        width: 50% !important;
        display: flex !important;
        flex-wrap: nowrap !important;
    }
}
