/* Ensure styles apply only to .campaign-track-table */

/* General Table Styles for campaign-track-table */
.campaign-track-table thead .ctHead {
  background: #e55426 !important;
  color: #fff !important;
  vertical-align: middle;
  text-align: left !important;
  text-shadow: 0.5px 0.5px #a39e9e;
}

/* Column-Specific Styles for Rounded Fill */
.campaign-track-table .submitted-column,
.campaign-track-table .delevery-count-column,
.campaign-track-table .status-column {
  border-radius: 12px;
  padding: 6px 12px;
  font-weight: bold;
}

.campaign-track-table .submitted-column {
  background-color: #f1f8ff;
  color: #0066cc;
}

.campaign-track-table .delevery-count-column {
  background-color: #e3f9e5;
  color: #4caf50;
}

.campaign-track-table .status-column {
  background-color: #ce850f;
}

.campaign-track-table .status-column span {
  background-color: rgba(254, 245, 236, 0.9);
  padding: 0.2rem 2rem;
  border-radius: 2rem;
  color: #f0f8ff;
}

/* Hover effect for rows in campaign-track-table */
.campaign-track-table tr:hover td {
  background-color: #f0f8ff;
}

/* Optional: Add hover effect for better interactivity */
.campaign-track-table td:hover {
  background-color: #f0f8ff;
  cursor: pointer;
}

/* Optional: Style for "No Records Found" message */
.campaign-track-table .text-center {
  font-size: 16px;
  color: #999;
  font-style: italic;
}

/* Added Sticky Table Header */
.campaign-track-table .table-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #ff6f00;  /* Orange background */
  color: white;  /* White text color */
  font-weight: bold;
}

/* Column-Specific Widths for .campaign-track-table */
.campaign-track-table th,
.campaign-track-table td {
  padding: 12px 18px;
  text-align: center;
  border-bottom: 2px solid #f1f1f1;
}

.campaign-track-table th {
  text-transform: uppercase;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.campaign-track-table td {
  background-color: white;
  color: #333;
}

/* Progress bar */
.campaign-track-table .progress {
  background-color: rgb(241, 227, 227) !important;
  color: #0066cc !important;
  padding: 1px 0px !important;
  height: 1.7rem !important;
}

.campaign-track-table .progressbarMain {
  padding: 2rem !important;
  margin: 1rem;
}

.campaign-track-table .tableHeadingBlock_CT {
  padding: 0rem 1rem !important;
}

/* Column-Specific Widths for .campaign-track-table */
.campaign-track-table th:first-child,
.campaign-track-table td:first-child {
  width: 50px !important;
  min-width: 50px;
  max-width: 50px;
  text-align: left;
  white-space: nowrap;
}

.campaign-track-table th:nth-child(2),
.campaign-track-table td:nth-child(2) { /* "Type" Column */
  width: 120px !important;
  min-width: 120px;
  text-align: left;
  white-space: nowrap;
}

.campaign-track-table th:nth-child(11),
.campaign-track-table td:nth-child(11) { /* "Type" Column*/
  max-width: 120px;
  text-align: left;
  // white-space: nowrap;
}

.campaign-track-table th:nth-child(3),
.campaign-track-table td:nth-child(3) { /* "Type" Column */
  width: 80px !important;
  min-width: 80px;
  max-width: 80px;
  text-align: left;
  white-space: nowrap;
}

.campaign-track-table th:nth-child(4),
.campaign-track-table td:nth-child(4) { /* "Type" Column */
  width: 60px !important;
  min-width: 70px;
  max-width: 70px;
  text-align: left;
  white-space: nowrap;
}

.campaign-track-table th:nth-child(5),
.campaign-track-table td:nth-child(5) { /* "Type" Column */
  width: 100px !important;
  min-width: 100px;
  max-width: 100px;
  text-align: left;
  white-space: nowrap;
}

.campaign-track-table th:nth-child(6),
.campaign-track-table td:nth-child(6) { /* "Type" Column */
  width: 100px !important;
  min-width: 100px;
  max-width: 100px;
  text-align: left;
  white-space: nowrap;
}

.campaign-track-table th:nth-child(11),
.campaign-track-table td:nth-child(11) { /* "Type" Column */
  width: 110px !important;
  min-width: 110px;
  max-width: 110px;
  text-align: left;
}

/* Container for scrollable table */
.table-container {
  max-height: 400px; /* Adjust height as needed */
  overflow-y: auto;
}


 .table-responsive-campaign tbody tr {
  position: relative;
}


 
/* Add left border on hover */
.table-responsive-campaign tbody tr:hover td:first-child::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px; /* Adjust thickness */
  height: 100%; /* Full height of the row */
  background-color: #F5A243; /* Orange border */
  border-radius: 4px; /* Optional: rounded edges */
}