.icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s;
}

.icon-wrapper:hover {
  background-color: #f0f0f0;
  /* Change to your preferred hover color */
}

.icon-wrapper:last-child {
  margin-right: 0;
  /* Remove margin for the last icon */
}

/* Custom styles for InputSwitch */
.p-inputswitch .p-inputswitch-slider {
  background-color: gray;
  /* Change this to your desired color */
}

.p-highlight .p-inputswitch-slider {
  background-color: #E55426;
  /* Change this to your desired color */
}


.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #28a745;
  /* Change this to your desired color when checked */
}

/* Optional: Customize the handle color */
.p-inputswitch .p-inputswitch-slider:before {
  background-color: white;
  /* Change this to your desired handle color */
}


@media screen and (max-width: 576px){
.tableHeadingBlock {
    flex-direction: column !important;
    align-items: center !important;
    gap: 10px;
}
.pageHeadingBlock.commonHeading .pageTitle h3 {
  font-size: 13px !important;
}
}

// Changes by Nandini
.zoom {
  animation: zoom-in-animation 0.5s ease-in-out;
  font-size: 18px !important;
}

@keyframes zoom-in-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2); /* Scale up slightly */
  }
  100% {
    transform: scale(1); /* Return to original size */
  }
}