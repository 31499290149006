@import '../../../assets/css/variable.scss';
@import '../../../assets/css/mixins.scss';

.ICPResults {
  @include card-box;
  padding: 0;
}

.disabled {
  pointer-events: none;
  /* Prevents clicks */
  opacity: 0.5;
  /* Makes it visually inactive */
}

//   .abmResults table tbody tr:hover td, .abmResults table tbody tr:focus td {
//     background: #FEF5EC;
//     border-color: rgba(245, 157, 64, 0.2);


// }
// .abmResults table tbody tr:hover td:first-child::before, .abmResults table tbody tr:focus td:first-child::before {
//     content: "";
//     position: absolute;
//     pointer-events: none;
//     left: 0;
//     top: 0;
//     width: 3px;
//     height: 100%;
//     background: #F5A243;
// }


.abmResults table tbody tr:hover {
  // box-shadow: 0px 9px 4px -6px orange;
  // transform: scaleX(1.02);
}

.abmResults table tbody tr:hover td {
  background: #FEF5EC;
  box-shadow: none;
  /* Reset box-shadow for td */
}

.abmResults table tbody tr:hover td:first-child::before {
  content: "";
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  width: 3px;
  height: 100%;
  background: #F5A243;
}

.tooltip-table {
  width: 100%;

  h6 {
    color: red;
  }

  p {
    color: red;
    font-size: 1.2rem;
  }

  table,
  .table {
    margin: 0;
    height: 50px;

    th,
    td {
      font-size: 1.2rem;
      line-height: 2rem;
      color: $secondary;
      font-weight: 400;
      padding: 1rem;

      @media screen and (max-width: $screen-lg) {
        white-space: nowrap;
      }
    }

    th {
      font-weight: 600;
    }
  }
}

.scroll-top {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px; // Adjust the distance from the bottom as needed
  transition: opacity 0.3s ease-in-out;

  .btn-width {
    background-color: #fff; // White background
    border: 2px solid $offgrey; // Black border
    width: 20px; // Adjust size as needed
    height: 30px; // Adjust size as needed
    border-radius: 50%; // Make it a circle
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .scroll-icon {
      color: $offblack; // Black icon color
      font-size: 20px; // Adjust icon size as needed
    }
  }
}


.scroll-bottom {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  .btn-width {
    background-color: #fff; // White background
    border: 2px solid $offgrey; // Black border
    width: 30px; // Adjust size as needed
    height: 30px; // Adjust size as needed
    border-radius: 50%; // Make it a circle
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .scroll-icon {
      color: $offblack; // Black icon color
      font-size: 20px; // Adjust icon size as needed
    }
  }
}

.pageHeadingBlock.commonHeading {
  .pageTitle {
    @media screen and (max-width: $screen-sm){
    // width: 130px;
    font-size: 1rem;
  }
  }
}

.disabled-row {
  opacity: 0.5; /* Reduce opacity to give a "faded" effect */
  pointer-events: none; /* Disable any interaction (click, hover, etc.) */
  background-color: #f0f0f0; /* Optional: change the background color for clarity */
  
  /* Optional: Change text color or other visual cues for disabled rows */
  color: #b0b0b0; /* Light gray text for clarity */
  
  /* Optionally, you could change the hover state to make it look fully disabled */
  &:hover {
    background-color: #f0f0f0;
    cursor: not-allowed; /* Show a "not-allowed" cursor on hover */
  }

  /* Disable any interaction within child elements */
  .checkBox input {
    pointer-events: none; /* Disable checkbox click */
  }
}


.myCheckbox {
  position: relative !important;
  width: 2rem !important;
  height: 2rem !important;
  appearance: none !important;
  

  &::before {
    content: '' !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 2.5px !important;
    background-color: transparent !important;
    border: 1.25px solid grey !important;
    border-radius: 0.4rem !important;
    cursor: pointer !important;
  }

  &:checked {
    &::after {
      content: '' !important;
      display: block !important;
      position: absolute !important;
      top: 3px !important;
      left: 7px !important;
      width: 6px !important;
      height: 11px !important;
      border: solid grey !important;
      border-width: 0 2px 2px 0 !important;
      transform: rotate(45deg) !important;
    }
  }
}
