@import '../../assets/css/variable.scss';
@import '../../assets/css/mixins.scss'; 

.spendingHistoryPage{
    background: $white;
    border-radius: 1rem;
    border: 1px solid $borderColor5;
}
.ICPResults table tbody tr:hover td, .ICPResults table tbody tr:focus td {
    background: #FEF5EC;
    border-color: rgba(245, 157, 64, 0.2);


}
.ICPResults table tbody tr:hover td:first-child::before, .ICPResults table tbody tr:focus td:first-child::before {
    content: "";
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 3px;
    height: 100%;
    background: #F5A243;
}
.pageHeadingBlock {
    &.buildYourICPHeading {
        @media screen and (max-width:643px) {
            justify-content: left !important;
        }
        h3 {
            padding-left: 14px;
        }
    }
}

// Filter Issue

.FilterUpdate {
    .dropdown {
        &.filterContent {
            .dropdown-menu {
                &.show {
                    min-width: 59.4rem !important;

                    @media screen and (max-width:992px) {
                        min-width: 40rem !important;
                    }
                    @media screen and (max-width:767px) {
                        min-width: 30rem !important;
                    }
                    @media screen and (max-width:600px) {
                        min-width: 20rem !important;
                    }

                    .dropdownHeading {
                        p {
                            font-size: 16px;
                            font-weight: 500;
                            color: #414141;
                            @media screen and (max-width:992px) {
                                font-size: 14px;
                            }
                        }
                    }
                    .dropdownContent {
                        padding: 3.1rem 2.4rem !important;
                        @media screen and (max-width:767px) {
                            height: 25rem;
                            padding: 1rem 1.5rem !important;
                        }
                        .cardDrop {
                            .rangeInputs {
                                @media screen and (max-width:992px) {
                                display: flex !important;
                                // gap: 10px !important;
                                grid-template-columns: inherit !important;
                                grid-gap: 10px !important;
                                flex-wrap: wrap;
                                }
                            }
                            .form-group {
                                span {
                                    @media screen and (max-width:992px) {
                                        font-size: 12px;
                                    }
                                }
                            }
                            .title {
                                font-size: 16px;
                                font-weight: 500;
                                color: #414141;
                                margin-bottom: 28px;
                                @media screen and (max-width:992px) {
                                    font-size: 13px;
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.custom-date-input {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    input {
        padding: 14px 24px;
        font-size: 16px;
        font-weight: 400;
        background: rgba(236, 236, 236, 0.2);
        border: none;
        color: rgba(65, 65, 65, 1);

        @media screen and (max-width:992px) {
            padding: 5px 8px;
            font-size: 12px;
        }
    }

    .custom-calendar-icon {
        position: absolute;
        right: 16px;
        pointer-events: none;
        top: 16px;
        
        @media screen and (max-width:992px) {
            top: 5px;
            right: 10px;
            line-height: 0 !important;
        }
        span {
            svg {
                @media screen and (max-width:992px) {
                    width: 12px;
                }
            }
        }
    }

}
  
