@import "../../assets/css/variable.scss";
@import "../../assets/css/mixins.scss";

.txtSize{
  font-size: 13px;
}

.timeCls {
    font-size: 12px;
    position: absolute;
    top: -10px;
    right:-10px;
    
    margin-right:10px;
    text-align: end;
}
.ViewAllList {
    .timeCls {
        font-size: 12px;
        position: inherit;
        top: inherit;
        right:inherit;
        margin-right:0px;
        text-align: end;
    } 
    .icon {
        &.iconBlk {
            svg {
                width: 4rem;
                height: 4rem;

                @media screen and (max-width: $screen-lg) {
                    width: 3rem;
                    height: 3rem;
                }
            }
        }
    }
}

/* width */
.pageContentBlock::-webkit-scrollbar {
    width: 10px;
    height: 2rem !important;
  }
  
  /* Track */
  .pageContentBlock::-webkit-scrollbar-track {
    background: #f1f1f1; 
    height: 2rem !important;
  }
   
  /* Handle */
  .pageContentBlock::-webkit-scrollbar-thumb {
    background: #888; 
    height: 2rem !important;
  }
  
  /* Handle on hover */
  .pageContentBlock::-webkit-scrollbar-thumb:hover {
    background: #555; 
  
  }

@media screen and (max-width: 480px) {
    .timeCls {
        text-align: end;
        margin-right: 3rem;
        padding-bottom: 3rem !important;
    }
   
}
@media screen and (max-width: 1024px) {
    .timeCls {
        text-align: end;
        margin-right: 3rem;
    }

}
.notificationFooter {
    .applyBtnSmall {
     @media screen and (max-width:767px) {
        white-space: nowrap;
        // width: 100%;
     }
    }
}