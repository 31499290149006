@import '../../assets/css/variable.scss';
@import '../../assets/css/mixins.scss'; 

.FindProspects{
    .icpForm{
        background: $white;
        border-radius: 1rem;
        padding: 4.6rem 7.8rem;
        height: 100%;
        @media screen and  (max-width: $screen-lg) {
            padding: 3.2rem 2.4rem;
        }
        .formSubmit .btn{
            max-width: 24.1rem;
            margin: 4.4rem auto 0;
        }
        .icpNote{
            font-style: italic;
            font-size: 1.16rem;
            line-height: 1.8rem;
            color: $offgrey4;
            margin: 0.8rem 0 0;
            strong{
                font-weight: 500;
            }
        }
        @include upload-document;
    }
}

// .error-input {
//     border: 2px solid #ff6b6b; /* Red border color */
//     box-shadow: 0px 0px 5px 0px rgba(255, 107, 107, 0.75); /* Subtle box shadow */
//     background-color: #fef2f2; /* Light red background */
//     transition: all 0.3s ease; /* Smooth transition */
//   }
  
//   .error-input:focus {
//     outline: none; /* Remove default focus outline */
//   }

.error-input {
    border-bottom: 1px solid red; /* Set border color to red when there's an error */
  }
  .box {
  border: 1px dotted red;
  border-radius: 10px; /* Set border color to red when there's an error */
  }
  
//   /* Add this CSS for the spinner Added by Rupali */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #e9cb25;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s linear infinite;
    margin-top: 40px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .hidden{
    display: none;
  }

  .job-title-add-span{
    position: absolute;
    right: 50px;  // Position the checkmark on the right
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;  // Adjust size of the tick
    color: #4CAF50;  // Green color for the tick
    // border-radius: 23%;  // Rounded corners
    // border: 1px solid #E55426;  // Border styling
    background: none;
    cursor: pointer;  // Make the button clickable
    padding: 5px;  // Padding for better button size
    transition: all 0.3s ease;  // Smooth transition for hover effect
    // &:hover {
    //   background-color: #E55426;  // Light green background on hover
    //   // transform: translateY(-30%) scale(1.2);  // Slightly enlarge on hover
    // }
  }


  .job-title-add-span-cross{
    position: absolute;
    right: 10px;  // Position the checkmark on the right
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;  // Adjust size of the tick
    color: #e00707;  // Green color for the tick
    // border-radius: 23%;  // Rounded corners
    // border: 1px solid #E55426;  // Border styling
    background: none;
    cursor: pointer;  // Make the button clickable
    padding: 5px;  // Padding for better button size
    transition: all 0.3s ease;  // Smooth transition for hover effect
    // &:hover {
    //   background-color: #E55426;  // Light green background on hover
    //   // transform: translateY(-30%) scale(1.2);  // Slightly enlarge on hover
    // }
  }