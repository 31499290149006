@import '../../assets/css/variable.scss';
@import '../../assets/css/mixins.scss';

.faqsPage{
    .faqCard{
        @include card-box;
        padding: 1.6rem 2.4rem 2.4rem;
        h5{
            font-size: 2rem;
            line-height: 2.8rem;
            font-weight: 400;
            padding-bottom: 1.2rem;
            margin-bottom: 2.2rem;
            border-bottom: 1px solid $offgrey;
        }
    }
    .faqContentCard{
        @media screen and  (max-width: $screen-sm) {
            margin-top: 2.4rem;
        }
    }
}
.faq-page{
    justify-content: flex-end !important;
}
.faq_block {
    @media screen and  (max-width: 767px) {
        margin-top: 15px !important;
      }
    input {
        padding: 10px;
        border-radius: 10px;
    }
}
.faq_main {
    @media screen and  (max-width: $screen-sm) {
      flex-direction: column;
    }
}