@import '../../assets/css/variable.scss';
@import '../../assets/css/mixins.scss';

.dropdown.daysSearchFilter.days-filter-block {
    .dropdown-menu {
        min-width: inherit;
        transform: translate(0px, 44px) !important;
        z-index: 1;
    }
}

.pageHeadingBlock {
    .pageTitle {
        img {
            width: 2.8rem;
            height: auto;
            object-fit: contain;
            margin-left: 0;
            position: relative;
            bottom: .5rem;
        }
    }

    .daysSearchFilter {
        @media screen and (max-width: $screen-xs) {
            width: 100%;
            margin: 1.6rem 0 0;
            padding-left: 36px;
        }

        .searchFilter {
            position: relative;
            @include flex-space-between;
            background: $white;
            border-radius: 1.2rem;
            padding: 0.8rem 1.6rem;
            // min-width: 37.4rem;
            min-height: 4.2rem;
            // text-decoration: underline;
            // cursor: pointer;
            justify-content: right;

            @media (min-width: 577px) and (max-width: $screen-sm) {
                min-width: 26rem;
            }

            @media screen and (max-width: $screen-xs) {
                // min-width: 100%;
            }

            img {
                width: 2.4rem;
                height: auto;
                object-fit: contain;
            }

            &::before {
                @include before-after;
                top: 50%;
                transform: translateY(-50%);
                right: 5.6rem;
                width: 0.1rem;
                height: 2.5rem;
                background: $offgrey;
            }
        }

        .dropdown-menu {
            @include dropdown-menu;
            padding: 0;
            min-width: 37.4rem;
            transform: translate(0px, 44px);

            @media screen and (max-width: $screen-xs) {
                min-width: 0px;
            }

            .filterHeading {
                padding: 1.6rem 2.4rem;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                @media screen and (max-width: $screen-lg) {
                    padding: 1.6rem;
                }


                h5 {
                    margin: 0;
                }
            }

            .filterLisiting {
                position: relative;
                padding: 2.4rem 2.4rem 2rem;

                @media screen and (max-width: $screen-lg) {
                    padding: 1.6rem;
                }

                form {
                    .form-group {
                        margin: 0 0 1.6rem;

                        label {
                            margin: 0;
                        }
                    }
                }
            }

            .selectCustomDate {
                width: 100%;
                padding-left: 3.2rem;
                margin: 1.6rem 0 6rem;

                .form-group {
                    margin-bottom: 1.2rem;
                }

                .form-control {
                    border: 1px solid $neutralGrey;
                    border-radius: 0.6rem;
                    padding: 0.8rem 1.2rem;
                    cursor: pointer;
                }

                &.disabled {
                    opacity: 0.4;
                    user-select: none;
                }
            }

            .filterFooter {
                padding: 2rem 0 0;

                &::before {
                    @include before-after;
                    left: 0;
                    bottom: 8rem;
                    background: rgba(0, 0, 0, 0.1);
                    width: 100%;
                    height: 1px;
                }
            }

            .btn {
                font-size: 1.2rem;
                line-height: 1.8rem;
                padding: 0.8rem 2.2rem;
                max-width: 8.4rem;
                margin: auto;
                height: auto;
            }
        }
    }
}

.dashboardPage {
    .contentCard {
        display: block;

        @media (min-width: $screen-sm) and (max-width: $screen-lg) {
            flex-direction: row;
        }

        .contentBox {
            position: relative;
            padding: 20px;

            p {
                color: $offblack;
                margin: 0;
                font-size: 1.2rem;

                @media (min-width: $screen-md) and (max-width: $screen-lg) {
                    font-size: 1.4rem;
                }

                @media screen and (max-width: $screen-xs) {
                    font-size: 1.2rem;
                }

                span {
                    font-weight: 700;
                    color: #414141;

                }
            }

            h3 {
                font-weight: 600;
                margin: 0;
            }
        }

        .recordBlock {
            @include flex-space-between;

            @media (min-width: $screen-md) and (max-width: $screen-lg) {
                justify-content: center;
            }

            @media (min-width: $screen-sm) and (max-width: $screen-md) {
                justify-content: flex-start;
            }

            @media screen and (max-width: $screen-xs) {
                justify-content: flex-start;
                margin-top: 1.6rem;
            }

            .recordCard {
                position: relative;
                @include record-card;
                padding-left: 2.8rem;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                &:last-child {
                    margin-left: 1.2rem;

                    @media (min-width: $screen-md) and (max-width: $screen-lg) {
                        margin-left: 0;
                        margin-top: 1rem;
                    }
                }

                h6 {
                    margin: 0;
                }

                p {
                    font-size: 1rem;
                    line-height: 1.4rem;
                    margin: 0;
                }

                .graphValueCircle {
                    position: absolute;
                    left: 0.8rem;
                    top: 1.2rem;
                    width: 1.2rem;
                    height: 1.2rem;
                    background: $circle;
                    border-radius: 100%;

                    &.scoreWise {
                        background: $grdient;
                    }
                }
            }
        }

        .recordContent {
            @media screen and (max-width: $screen-xs) {
                padding-left: 1rem;
            }

            h4 {
                @include flex-align-center;
                font-weight: 400;
                line-height: 3.6rem;
                margin: 0;

                @media (min-width: $screen-md) and (max-width: $screen-lg) {
                    font-size: 2rem;
                    line-height: 3rem;
                }

                @media screen and (max-width: $screen-lg) {
                    line-height: normal;
                }

                svg {
                    margin-left: 0.8rem;
                }
            }

            p {
                color: $offgrey1;
                font-size: 1.2rem;
                line-height: 1.8rem;
                margin: 0;
            }
        }

        .graphBox {
            width: 100%;
            min-height: 180px;
            align-self: self-start;
            // max-width: 23.8rem;

            @media (min-width: $screen-md) and (max-width: $screen-lg) {
                margin-left: 0;
            }

            @media screen and (max-width: $screen-xs) {
                margin-left: 0;
            }

            svg>g>g:first-child {
                pointer-events: none
            }
        }
    }

    .detailsOverviewGraph {
        background: $white;
        padding: 2.4rem;
        border-radius: 1rem;
        margin: 2.4rem 0 0;

        &.GraphBlk {
            margin: 24px 0 !important;
        }

        @media screen and (max-width: $screen-sm) {
            margin: 0;
        }

        .headingBlock {
            @include flex-space-between;
            padding-bottom: 1.2rem;
            border-bottom: 1px solid $offgrey;
            margin: 0 0 1.6rem;

            @media screen and (max-width: $screen-xs) {
                display: block;
            }

            h5 {
                font-weight: 400;
                margin: 0;
            }

            p {
                font-size: 1.4rem;
                color: $offblack;
                margin: 0;

                @media screen and (max-width: $screen-xs) {
                    text-align: right;
                }
            }
        }

        .contentBlock {
            @include nav-tabs;

            @media screen and (max-width: $screen-lg) {
                overflow-x: auto;
            }

            .tabcard {
                box-shadow: 0 0 0 5px rgba(229, 84, 38, 0.2);
                width: 170px;
                height: 80px;
                border-radius: 30px;
            }
        }

    }

    &.sideContentWrapper {
        .contentCard {
            @media (min-width: $screen-sm) and (max-width: $screen-md) {
                flex-direction: row;
            }

            .graphBox {
                width: 100%;
                max-width: 20rem;
            }

            .contentBox {
                @media (min-width: $screen-md) and (max-width: $screen-lg) {
                    flex-direction: row;
                }

                @media screen and (max-width: $screen-xs) {
                    flex-direction: row;
                }
            }

            &.creditsCard {
                flex-direction: column;
                padding: 2.4rem;

                .creditsContent {
                    @include flex-space-between;
                    margin: 0 0 3.2rem;

                    h3 {
                        font-weight: 600;
                        margin: 0;
                    }

                    p {
                        margin: 0;
                    }

                    .availableCredits {
                        p {
                            font-size: 1.8rem;
                            line-height: 2.6rem;
                            color: $offblack;
                            font-weight: 500;

                            @media (min-width: $screen-md) and (max-width: $screen-lg) {
                                font-size: 1.2rem;
                                line-height: 1.8rem;
                            }

                            @media screen and (max-width: $screen-xs) {
                                font-size: 1.2rem;
                                line-height: 1.8rem;
                            }
                        }
                    }

                    .btn {
                        padding: 0.8rem 2.2rem;
                        font-size: 1.2rem;
                        line-height: 1.8rem;
                        height: auto;
                        max-width: 12.4rem;
                        margin: auto;
                    }

                    .viewHistory {
                        font-size: 1.4rem;
                        line-height: 2.1rem;
                        font-weight: 300;
                        text-decoration: underline;
                        color: $offblack;
                        margin: 0.8rem 0 0;

                        &:hover,
                        &:focus {
                            color: $primary;
                        }

                        @media (min-width: $screen-md) and (max-width: $screen-lg) {
                            font-size: 1.2rem;
                            line-height: 1.8rem;
                        }

                        @media screen and (max-width: $screen-xs) {
                            font-size: 1.1rem;
                            line-height: 1.2rem;
                        }
                    }
                }

                .recordBlock {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-gap: 1.5rem;

                    @media screen and (max-width: $screen-sm) {
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: 1rem;
                    }

                    @media screen and (max-width: $screen-xs) {
                        grid-template-columns: 1fr;
                        margin-top: 0;
                    }

                    .recordCard {
                        padding: 1.6rem;
                        text-align: center;
                        border-radius: 0.8rem;

                        &:not(:first-child) {
                            margin-left: auto;
                            margin-top: 0;
                            width: 100%;
                        }

                        @media screen and (max-width: $screen-xs) {
                            margin: 0 auto 1.6rem;
                            width: 100%;
                            max-width: 20rem;
                        }

                        h6 {
                            font-size: 1.4rem;
                            font-weight: 400;
                        }

                        p {
                            text-transform: capitalize;
                            color: $primary !important;
                            font-size: 1.6rem;
                            line-height: 2.4rem;
                            letter-spacing: 0.6;
                        }
                    }
                }

                .buyCredits {
                    .btn {
                        margin-top: 2rem;
                    }

                    @media screen and (max-width: $screen-xs) {
                        margin: auto;
                    }
                }
            }
        }
    }
}

.table-block {
    @media screen and (max-width: $screen-xs) {
        flex-direction: column;
    }

}

.graph_block {
    overflow-x: auto;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.chart-container {
    height: 100%;
    // max-height: 400px; /* Adjust as needed */
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    min-width: 600px;

    p {
        margin-top: 100px;
    }
}

.button-group {
    @media screen and (max-width: $screen-xs) {
        margin-right: 20px;
        display: flex;
        gap: 10px;
    }
    &.ButtonYearBlk {
        @media screen and (max-width: $screen-xs) {
            margin-right: 0px;
            display: flex;
            gap: 10px;
        }
    }
}

.days-filter-block {
    background-color: #fff;
    display: flex;
    min-width: 25.4rem;
    justify-content: space-between;
    border-radius: 1.2rem;
    align-items: center;
    padding: 0px 0px 0px 15px;
    // text-decoration: underline;
    cursor: pointer;

    @media screen and (max-width: $screen-xs) {
        width: 100%;
        min-width: 100%;
    }
}


.no-data {
    display: flex;
    justify-content: center;

    @media screen and (max-width: $screen-xs) {
        padding-top: 120px;
    }
}

.contentCard {
    &.creditsCard .creditsContentblk {
        .btn {
            @media screen and (max-width:767px) {
                width: fit-content;
                white-space: nowrap;
                height: 40px;
            }
        }

        .btnPrimaryOutline {
            @media screen and (max-width:1280px) {
                width: fit-content;
                white-space: nowrap;
            }

            @media screen and (max-width:767px) {
                width: fit-content;
                white-space: nowrap;
                height: 40px;
            }
        }
    }
}

.pageContentWrapp {
    .contentWrapper {
        .contentCard {
            &.mainCard {
                @media screen and (max-width:910px) {
                    padding: 34px 20px;
                }

                @media screen and (max-width:800px) {
                    padding: 34px 0px;
                }
            }
            &.mainCardBLK {
                @media screen and (max-width:767px) {
                    margin-bottom: 24px !important;
                }
            }
        }
    }

    .dashboardPage {
        .contentCard {
            .contentBox {
                @media screen and (max-width:910px) {
                    padding: 0;
                }
            }
        }
    }

    .contentBLK {
        min-height: 208px;
    }

    .contentCard {
        &.creditsCard {
            margin: 0 !important;
            min-height: 208px;
        }
    }
}
.RecordTime {
    .recordCardMain {
        @media screen and (max-width:600px) {
        padding: 0;
        }
    }

}