@import '../../assets/css/variable.scss';
@import '../../assets/css/mixins.scss';

.myProfilePage {
    .contentCard {
        flex-direction: column;
        justify-content: center;

        @media (min-width: $screen-md) and (max-width: $screen-lg) {
            justify-content: flex-start;
        }

        .userDetails {
            .userImage {
                img {
                    width: 14.6rem;
                    height: 14.6rem;
                    border-radius: 100%;
                    margin: 0 auto 1.4rem;

                    @media screen and (max-width: $screen-lg) {
                        width: 14.1rem;
                        height: 14.1rem;
                    }
                }
               
            }
            .userNameLetter{
                position: relative;
                color: $white;
                font-size: 6rem;
                line-height: normal;
                font-weight: 500;
                margin: 0 auto;
                width: 10.6rem !important;
                height: 10.6rem !important;
                background: $orange3;
            }
            .userInfo {
                h4 {
                    font-size: 2.2rem;
                    line-height: normal;
                    font-weight: 500;
                    opacity: 0.9;
                    margin: 0;
                }

                p {
                    color: $offblack;
                }
            }

            .socialIcons {
                ul {
                    @include flex-justify-center;
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    li {
                        @include flex-justify-center;
                        width: 4rem;
                        height: 4rem;
                        padding: 0.8rem;
                        border: 1px solid $borderColor4;
                        border-radius: 0.8rem;
                        margin: 0 1rem;

                        @media screen and (max-width: $screen-lg) {
                            margin: 0 0.5rem;
                        }

                        a {
                            line-height: 2rem;
                        }

                        svg {
                            width: 2rem;
                            height: 2rem;
                        }

                        &:hover,
                        &:focus {
                            background: $lightGrey;
                            border-color: $lightGrey;
                        }
                    }
                }
            }
        }

        &.creditsCard {
            .creditsContent {
                @include flex-space-between;
                margin: 0 0 4.2rem;

                h3 {
                    font-weight: 600;
                    margin: 0;
                }

                p {
                    margin: 0;
                }

                .availableCredits {
                    p {
                        font-size: 1.8rem;
                        line-height: 2.6rem;
                        color: $offblack;
                        font-weight: 500;

                        @media screen and (max-width: $screen-lg) {
                            font-size: 1.4rem;
                            line-height: 2rem;
                        }
                    }
                }

                .btn {
                    padding: 1rem 2.2rem;
                    font-size: 1.4rem;
                    line-height: normal;
                    font-weight: 500;
                    height: auto;
                    max-width: fit-content;
                    min-width: fit-content;
                    margin: auto;

                    @media screen and (max-width: $screen-lg) {
                        min-width: auto;
                    }
                    @media screen and (max-width: 1200px) {
                        width: 100%;
                    }
                }
            }

            .recordBlock {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 1.5rem;

                @media screen and (max-width: $screen-lg) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media screen and (max-width: $screen-xs) {
                    grid-template-columns: 1fr;
                }

                .recordItem {
                    width: 100%;

                    @media screen and (max-width: $screen-xs) {
                        width: 100%;
                        max-width: 20rem;
                        margin: auto;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }

                    .recordGraph {
                        margin: 0 0 1.5rem;

                        img {
                            width: 8.9rem;
                            height: 8.9rem;
                            object-fit: contain;
                            margin: auto;
                        }
                    }
                }

                .recordCard {
                    position: relative;
                    @include record-card;
                    padding: 1rem 1.4rem;
                    text-align: center;
                    border-radius: 0.8rem;
                    margin-left: 0;
                    height: auto;

                    h6 {
                        font-weight: 400;
                        margin: 0 0 0.4rem;
                    }

                    p {
                        text-transform: uppercase;
                        color: $primary;
                        font-size: 1.1rem;
                        line-height: 1.7rem;
                        letter-spacing: 0.6px;
                        white-space: nowrap;
                        margin: 0;
                    }
                }
            }
        }
    }

    .userPersonalDetails {
        background: $white;
        padding: 2.4rem;
        border-radius: 1rem;
        // margin: 2.4rem 0 0;

        @media screen and (max-width: $screen-sm) {
            margin: 0;
        }

        .headingBlock {
            @include flex-space-between;
            padding-bottom: 1.2rem;
            border-bottom: 1px solid $offgrey;
            margin: 0 0 1.6rem;

            h5 {
                font-weight: 400;
                margin: 0;
            }

            p {
                font-size: 1.4rem;
                color: $offblack;
                margin: 0;
            }

            .btn {
                font-family: $fontInter;
                font-size: 1.2rem;
                line-height: normal;
                font-weight: 500;
                padding: 1rem 2rem;
                height: auto;
            }
        }

        .contentBlock {
            .col-12 {
                margin: 0 0 3.2rem;

                &:last-child {
                    margin: 0;
                }
            }

            .form-group {
                p {
                    margin: 0;

                    span {
                        display: block;
                        font-size: 1.4rem;
                        line-height: 2.4rem;
                        color: $offgrey2;
                        margin: 0 0 0.8rem;
                    }
                }
            }
        }
    }

    &.sideContentWrapper {
        .cardContent {
            ul {
                padding: 0;
                margin: 0 0 2rem;
                list-style: none;

                li {
                    @include flex-space-between;
                    background: $lightGreyBackground;
                    border-radius: 0.8rem;
                    padding: 1.6rem;
                    margin: 0 0 1.2rem;
                    flex-wrap: nowrap;

                    &:last-child {
                        margin: 0;
                    }

                    span {
                        text-align: right;
                        font-weight: 500;
                        padding-left: 1.6rem;

                        @media screen and (max-width: $screen-lg) {
                            padding-left: 0.8rem;
                        }
                    }

                    @media screen and (max-width: $screen-lg) {
                        font-size: 1.4rem;
                        line-height: 2rem;
                        padding: 1rem;
                        flex-wrap: nowrap;
                    }
                }
            }

            .btn {
                font-size: 1.2rem;
                line-height: normal;
                font-weight: 500;
                padding: 1rem 2rem;
                max-width: fit-content;
                height: auto;
                margin: auto;
            }

            table {
                background: $lightGreyBackground;
                border-radius: 0.8rem;

                th,
                td {
                    font-size: 1.2rem;
                    line-height: normal;
                    font-weight: 300;
                    border-bottom: 1px solid $borderColor2;
                }

                th {
                    text-transform: uppercase;
                    font-weight: 500;
                    border-bottom: 1px solid $borderColor1;
                }

                td {
                    &:last-child {
                        font-weight: 500;
                    }
                }

                tr {
                    &:last-child {
                        td {
                            border-bottom: none;
                        }
                    }
                }

                tbody {
                    tr {

                        &:hover,
                        &:focus {
                            td {
                                background: $offprimary;
                                border-color: $borderColor3;

                                &:first-child {
                                    position: relative;

                                    &::before {
                                        @include before-after;
                                        left: 0;
                                        top: 0;
                                        width: 3px;
                                        height: 100%;
                                        background: $orange;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cardContent.table-responsive {
        max-height: 42rem;
        overflow-y: auto;
    }
    .SpaceBLOCk {
        margin-top: 2.4rem;
    }
}