.pieChart {
        position: "relative";
        height: 400px;
        width: 100% !important;
        padding: 20px;
}

.geo-dropdown {
        position: absolute;
        top: 35px !important;
        right: 100px !important;
        z-index: 10 !important;
}



@media screen and (max-width:1280px) {
        .geo-dropdown {
                right: 0px !important;
        }
}

@media screen and (max-width:767px) {
        .dropdown_open_geo {
                width: 74.90px !important;
        }
}

@media screen and (max-width:576px) {
        .geo-dropdown {
                top: 42px !important;
        }
}

@media screen and (max-width: 480px) {
        .pieChart {
                height: 400px !important;
                /* width: 250px !important; */
        }

        /* .geo-dropdown {
                position: absolute;
                top: 342px !important;
                left: 106px !important;
                z-index: 0 !important;
        } */
}

@media screen and (max-width:333px) {
        .geo-dropdown {
                top: 32px !important;
        }
}