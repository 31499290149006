@import '../../../assets/css/variable.scss';
@import '../../../assets/css/mixins.scss';

.authPages.registerPage{
    .formWrapper{
        padding-top: 10rem;
        @media screen and  (max-width: $screen-sm) {
            padding-top: 0; 
        }
    }
}

.passwordField {
    @include flex-align-center;

}
  
