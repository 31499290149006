.freepageContentBlock.freeBlockBG {
    display: block;
    /* justify-content: center; */
    /* align-items: center; */
    height: 100vh; /* Full viewport height */
  }
  
  .freeBlockBG .freecontentWrapper {
    width: 100% !important; 
    margin: auto;
    position: relative;
  }
  
  .freeBlockBG .bgTable {
    /* background-image: url('../../../../assets/images/vailsresultblur.jpg'); Replace with your image path */
    background-size: 100% 100%;
    /* background-position: center; */
    height: 100vh; /* Set the height of the background image */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    /* border-radius: 10px;  */
    /* color: white;  */
    /* text-align: center; */
    /* padding: 20px;  */
    position: absolute;
    width: 100%;
  }
  .freeBlockBG .FreeBlockText {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 0 15px;
    background-color: rgba(255,255,255,0.6);
}

  .freeBlockBG .textOverlay {
    /* font-size: 2rem; */
    margin-bottom: 10px;
    /* added */
    /* position: fixed;  */
    /* top: 50%; */
    /* z-index: 9999 !important; */
}
.freeBlockBG .textOverlay h1 {
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
  margin: 0;
}
  
  .freeBlockBG .ctaButton {
    padding: 6px 10px;
    font-size: 1.5rem;
    color: white;
    background-color: #E55426; /* Change to your preferred color */
    border: none;
    border-color: #E55426;
    border-radius: 40px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 auto ;
    min-width: 12.1rem;
    /* added */
   

  }
  
  .freeBlockBG .ctaButton:hover {
    background-color: rgb(238, 151, 110);
     
  }
  @media screen and (max-width: 480px) {
    .freeBlockBG .bgTable {
      position: relative;
    }
    .freeBlockBG .textOverlay {
      /* font-size: 1.5rem !important; */
      /* margin-bottom: 10px; */
      /* added */
      /* position: absolute; 
      top: 150px; */
      /* z-index: 9999  */
  }
  .freeBlockBG .textOverlay h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
  }
  .freeBlockBG .FreeBlockText {
    z-index: 9999 !important;
  }
  .freeBlockBG .ctaButton{
       /* added */
       /* position: absolute;
       top: 255px; */
       /* z-index: 999; */
  }
  }