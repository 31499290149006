@import '../../../assets/css/variable.scss';
@import '../../../assets/css/mixins.scss';

.loginPage{
    .siteLogo{
        @media screen and  (max-width: $screen-sm) {
            margin: 0 0 2.4rem;
        }
    }
}
.eye-icon{
    cursor: pointer;
}
.eye-icon i{
    font-size: 1.7rem;
    border: none;
    cursor: pointer;
}


  
  