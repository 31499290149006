@import '../../../../assets/css/variable.scss';
@import '../../../../assets/css/mixins.scss';

.ProfilePictureModal{
    &.modal .modal-dialog .modal-body{
        max-width: 100%;
    }
    .userImage{
        margin: 2.4rem auto 0;
    }
    .buttonDiv{
        margin: 2.4rem 0 0;
    }
    .buttonGroup{
        margin: 2.4rem 0 0;
        .btn{
            width: 100%;
        }
    }
}
.buttonGroup {
    &.deletImgBlk {
        button {
            &.btnPrimaryOutline {
                &:hover,&:focus {
                    svg {
                        path {
                            fill: #ffffff;
                        }
                    }
                }
            }
            
        }
    }
}