@import '../../assets/css/variable.scss';
@import '../../assets/css/mixins.scss'; 

.myDownloadList{
    @include card-box;
    padding: 0;
    .tableContentBlock .statusBox{
        display: inline-flex;
        min-width: 12rem;
    }
    .downloadSelected .btn{
        padding: 1rem 1.6rem;
        border: 1px solid transparent;
        cursor: pointer;
        border-radius: 0.8rem;
        color: $secondary;
        svg{
            margin-right: 0.8rem;
        }
        &:focus{
            border-color: $borderColor1;
        }
        @media screen and  (max-width: $screen-sm) {
            border-radius: 0.8rem;
            border-color: $borderColor1;
            margin: 0 0.5rem 0.5rem 0;
            padding: 1rem 1rem;
        }
    }
    .disabled-svg {
        opacity: 0.5; /* Reduce opacity to indicate disabled state */
         pointer-events: none; /* Disable pointer events so that it cannot be clicked */
      }
}

// .myDownloadList table tbody tr:hover td, .myDownloadList table tbody tr:focus td {
//     background: #FEF5EC;
//     border-color: rgba(245, 157, 64, 0.2);


// }
// .myDownloadList table tbody tr:hover td:first-child::before, .myDownloadList table tbody tr:focus td:first-child::before {
//     content: "";
//     position: absolute;
//     pointer-events: none;
//     left: 0;
//     top: 0;
//     width: 3px;
//     height: 100%;
//     background: #F5A243;
// }
// .myDownloadList table tbody tr:hover {
//     // box-shadow: 0px 9px 4px -6px orange;
//     transform: scaleX(1.02);
// }

.myDownloadList table tbody tr:hover td {
    background: #FEF5EC;
    box-shadow: none; /* Reset box-shadow for td */
}

.myDownloadList table tbody tr:hover td:first-child::before {
    content: "";
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 3px;
    height: 100%;
    background: #F5A243;
}
.downloadBlk {
    margin-left: 20px !important;
    margin-right: 20px !important;
}
.custom-date-input {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    input {
        padding: 14px 24px !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        background: rgba(236, 236, 236, 0.2) !important;
        border: none !important;
        color: rgba(65, 65, 65, 1) !important;

        @media screen and (max-width:992px) {
            padding: 5px 8px !important;
            font-size: 12px !important;
        }
    }

    .custom-calendar-icon {
        position: absolute;
        right: 16px;
        pointer-events: none;
        top: 16px;
        
        @media screen and (max-width:992px) {
            top: 5px;
            right: 10px;
            line-height: 0 !important;
        }
        span {
            svg {
                @media screen and (max-width:992px) {
                    width: 12px;
                }
            }
        }
    }

}



  .mydownlaodlist td:first-child {
    width: 50px !important;
    min-width: 50px;
    max-width: 50px;
    
  }

  .mydownlaodlist td:nth-child(2) {
    width: 442px !important;
    min-width: 442px!important;
    max-width: 442px !important;
    
  }