@import "../../assets/css/variable.scss";
@import "../../assets/css/mixins.scss";

.abmLAL {
  .ABMupdate {
    padding: 4.4rem 7.8rem;
    @media screen and (max-width:991px) {
      padding: 3.4rem 3.6rem;
    }
  }
  .abmFormHeadig {
    // margin: 0 0 5.6rem;
    margin: 0 2.4rem 0rem;
    padding: 2.4rem 0 1.2rem;
    border-bottom: 1px solid rgba(65, 65, 65, 0.2);
    h5 {
      font-weight: 400;
      margin: 0;

      @media screen and (max-width:991px) and (min-width:767px) {
        height: 5.2rem;
      }
    }
    // &::before {
    //   @include before-after;
    //   top: 8.5rem;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   width: calc(100% - 4.8rem);
    //   height: 1px;
    //   background: $offgrey;
    // }
  }
  .icpForm {
    position: relative;
    background: $white;
    border-radius: 1rem;
    // padding: 4.6rem 7.8rem;
    height: 100%;
    @media screen and (max-width: $screen-lg) {
      // padding: 3.2rem 2.4rem;
    }
    .formSubmit .btn {
      max-width: 24.1rem;
      margin: 0 auto;
    }
    .icpNote {
      font-style: italic;
      font-size: 1.16rem;
      line-height: 1.8rem;
      color: $offgrey4;
      margin: 0.8rem 0 24px;
      padding: 0 2.4rem;
    text-align: center;
      strong {
        font-weight: 500;
      }
    }
    @include upload-document;
  }
  .abmForm {
    height: 100%;
    &.lalForm {
      .icpForm .uploadDocuments {
        .uploadDocInput,
        .uploadDocContent {
          // height: 48.4rem;
          @media screen and (max-width: $screen-sm) {
            height: 17.8rem;
          }
        }
      }
    }
  }
}
.error-input {
  border-bottom: 1px solid red; /* Set border color to red when there's an error */
}
.box {
border: 1px dotted red;
border-radius: 10px; /* Set border color to red when there's an error */
}