@import '../../../../assets/css/variable.scss';
@import '../../../../assets/css/mixins.scss'; 

.ExportModal{
    p{
        margin-bottom: 3rem;
    }
}
.exportItems.me-0 {
    a{
        &:disabled {
            svg {
                path {
                    fill:#575454;
                }
            }
        }
        &.disabled {
            svg {
                path {
                    fill:#575454;
                }
            }
        }
    }
}