@mixin flex-align-center {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

@mixin flex-justify-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

@mixin flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

@mixin flex-justify-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
}

@mixin flex-column-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
}

@mixin before-after {
    content: '';
    position: absolute;
    pointer-events: none;
}

@mixin card-box {
    background: $white;
    border-radius: 1rem;
    padding: 2.4rem;
}

@mixin content-card {
    // display: flex;
    justify-content: space-between;
    background: $white;
    padding: 1.4rem;
    border-radius: 1rem;
    height: 100%;
    margin: 0 0 2.4rem;

    @media screen and (max-width: $screen-xs) {
        padding: 1.6rem;
        flex-direction: column;
    }
}

@mixin record-card {
    @include flex-column;
    background: $offblue;
    padding: 0.6rem 0.8rem;
    border-radius: 1rem;
    height: 100%;
}

@mixin nav-tabs {
    .nav-tabs {
        border: none;

        @media screen and (max-width: $screen-lg) {
            flex-wrap: nowrap;
            flex-direction: column;
        }

        .nav-link {
            background: transparent;
            font-size: 1.4rem;
            line-height: 2rem;
            font-weight: 400;
            color: $offgrey1;
            padding: 0.8rem 1.6rem;
            border: none;
            border-bottom: 2px solid transparent;

            @media screen and (max-width: $screen-lg) {
                white-space: nowrap;
            }

            &.active,
            &:hover,
            &:focus {
                color: $primary;
                border-color: $primary;
            }

            &:not(:last-child) {
                margin-right: 2.4rem;

                @media screen and (max-width: $screen-xs) {
                    margin-right: 1rem;
                }
            }

            @media screen and (max-width: $screen-xs) {
                padding: 0.8rem;
            }
        }
    }

    .tab-content {
        margin: 1.6rem 0 0;
    }
}

@mixin dropdown-menu {
    background: $white;
    border-radius: 1rem;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.10);
    border: none;
    width: 100%;

    ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            position: relative;
            padding: 1.2rem 2.4rem;
            border-bottom: 1px solid $borderColor;
            margin: 0;

            &:last-child {
                border-bottom: none;
            }

            a {
                @include flex-align-center;
                color: $secondary;
                font-size: 1.4rem;
                line-height: 2rem;

                svg {
                    width: 2rem;
                    height: 2rem;
                    position: absolute;
                    left: 1.8rem;
                    top: 1.2rem;

                    path {
                        fill: $black;
                    }
                }

                &:hover,
                &:focus {
                    color: $primary;

                    svg {
                        path {
                            fill: $primary;
                        }
                    }
                }
            }
        }
    }
}

@mixin image-upload {
    .userImageUpload {
        position: relative;

        .userImgUploadInput {
            opacity: 0;
            width: 100%;
            height: 11.2rem;
            position: relative;
            z-index: 9;
            cursor: pointer;
        }

        .userImgUploadContent {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            @include flex-column;
            align-items: center;
            width: 100%;
            height: 11.2rem;
            border: 1px dashed rgba(0, 0, 0, 0.22);
            border-radius: 0.8rem;
            background: $lightGreyBackground;

            svg {
                margin-bottom: 1rem;
            }

            p {
                color: $offblack;
                text-align: center;

                span {
                    display: block;
                    font-size: 1.4rem;
                    color: $offgrey3;
                }
            }
        }

        .uploadedFile {
            position: relative;
            margin: 1.6rem 0;

            .fileDetilsItem {
                position: relative;
                @include flex-align-center;
                display: inline-flex;
                background: $lightGreyBackground;
                padding: 1rem;
                border-radius: 0.8rem;
                border: 1px solid $greyBackground1;
               

                &::before {
                    @include before-after;
                    top: 0;
                    right: 3.2rem;
                    width: 1px;
                    height: 100%;
                    background: $greyBackground1;
                }

                img {
                    width: auto;
                    max-height: 2rem;
                    object-fit: contain;
                    margin-right: 0.8rem;
                }

                p {
                    @include flex-align-center;
                    margin: 0 6rem 0 0;
                }
            }
        }
    }
}

@mixin upload-document {
    .uploadedFile {
        position: relative;
        margin: 0 0 1.2rem;

        .fileDetilsItem {
            position: relative;
            @include flex-space-between;
            background: $white;
            width: 100%;
            padding: 1rem;
            border-radius: 0.8rem;
            border: 1px solid $greyBackground1;
             
            @media screen and (max-width: $screen-sm) {
                justify-content: center;
                flex-direction: column;
             }

             @media screen and (max-width: $screen-md) {
                justify-content: center;
                flex-direction: column;
             }

             @media screen and (max-width: $screen-xs) {
                justify-content: center;
             }

            img {
                width: auto;
                max-height: 4.8rem;
                object-fit: contain;
                margin-right: 2rem;

                @media screen and (max-width: $screen-sm) {
                    width: 30px;
                }
                @media screen and (max-width: $screen-md) {
                    max-height: 2.8rem;
                }
            }

            p {
                font-size: 1.4rem;
                line-height: 1.8rem;
                @include flex-align-center;
                margin: 0;

                @media screen and (max-width: $screen-sm) {
                    flex-wrap: nowrap;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .uploadDocuments {
        position: relative;

        .uploadDocInput {
            opacity: 0;
            width: 100%;
            height: 20rem;
            position: relative;
            cursor: pointer;

        }

        .uploadDocContent {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            @include flex-column;
            align-items: center;
            width: 100%;
            height: 20rem;
            border: 1px dashed rgba(0, 0, 0, 0.22);
            border-radius: 0.8rem;
            background: $lightGreyBackground;
            padding: 1rem;
            pointer-events: none;
            @media screen and (max-width: $screen-sm) {
              flex-direction: inherit;
            }

            svg {
                margin-bottom: 1rem;
            }

            p {
                color: $offblack;
                text-align: center;
                word-wrap: break-word;
                width: inherit;
                span {
                    display: block;
                    font-size: 1.4rem;
                    color: $offgrey3;
                }
            }
        }

        .btn {
            width: auto;
            min-width: 14rem;
            margin: auto;

            svg {
                margin-right: 0.8rem;
            }
        }
    }
}