//common-css
.form-group input.form-control {
    &.passWordC {
        @media screen and (max-width: 768px) {
            width: inherit;
        }
    }
}

.passWordC {
    width: inherit;

    &.input-group {
        @media screen and (max-width:767px) {
            position: inherit;
            flex-wrap: inherit;
            align-items: inherit;

        }
    }
}

.authPages {
    position: relative;
    background: $white;
    padding: 2rem;
    height: 100%;
    min-height: 100vh;

    @media screen and (max-width: $screen-sm) {
        padding: 2.5rem 1rem;
    }

    .col-12 {
        &:first-child {
            align-self: self-start;
        }
    }

    .backLink {
        position: absolute;
        top: 7rem;
        left: 52%;

        @media screen and (max-width: $screen-sm) {
            top: 1rem;
            left: 2.2rem;
        }

        a {
            @include flex-align-center;
            color: $secondary;

            &:hover,
            &:focus {
                color: $primary;
            }

            i {
                font-size: 2rem;
                margin-right: 1rem;
            }
        }
    }

    .forgotPassword {
        font-size: 14px;
    }

    .siteLogo {
        // position: absolute;
        // top: 1rem;
        // left: 50%;
        display: flex;
        margin: auto;
        margin-top: -35px;
        width: 50%;
        margin-bottom: 30px;

        @media screen and (max-width: $screen-sm) {
            position: relative;
            top: auto;
            left: auto;
            margin: 0 0 4.4rem;
            width: 100%;
        }

        img {
            width: auto;
            max-height: 4rem;
            margin: auto;
        }
    }

    .form-group {
        position: relative;
        margin: -3px 0 1rem;
    }

    .smText {
        font-size: 1.4rem;
        line-height: 2rem;
    }

    .signinButton {
        .btn {
            i {
                color: $blue;
                font-size: 2.5rem;
                margin-right: 1rem;
            }

            &:hover,
            &:focus {
                i {
                    color: $white;
                }
            }
        }
    }

    .formWrapper {
        padding: 2.4rem;
        max-width: 54rem;
        margin: auto;

        @media screen and (max-width: $screen-sm) {
            padding: 0;
        }

        h2 {
            text-align: center;
            font-weight: 500;
            margin: 0 0 1rem;
            font-size: 2rem;
        }

        h3 {
            text-align: center;
            font-weight: 500;
            margin: 0 0 1rem;

        }

        .signinButton .btn {
            font-weight: 500;
            width: -moz-fit-content;
            width: fit-content;
            margin: 0 auto;
            height: auto;
            padding: 8px 50px !important;
        }

        .optionText {
            position: relative;
            text-align: center;
            margin: 1rem 0 0;

            p {
                font-size: 1.6rem;
                line-height: 2.8rem;
                color: $darkGrey;
                display: inline;
                text-align: center;
                background: $white;
                padding: 0 1rem;
                position: relative;
                z-index: 2;

                @media screen and (max-width: $screen-sm) {
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                }
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                height: 1px;
                background: $lightGrey;
            }
        }

        .authForm {
            margin: 1rem 0 0;



            .fieldsGroup {
                @include flex-space-between;
                flex-wrap: wrap;

                @media screen and (max-width: $screen-xs) {
                    justify-content: center !important;
                }
            }

            .rememberDays {
                position: relative;
                display: grid;
                grid-template-columns: auto 1fr;
                grid-gap: 1rem;

                @media screen and (max-width: $screen-xs) {
                    div {
                        width: 100% !important;
                    }

                    iframe {
                        transform: scale(0.8);
                    }
                }
            }

            .passwordField {
                position: relative;

                .fa-eye-slash,
                .fa-eye {
                    position: absolute;
                    bottom: 1.5rem;
                    right: 1.2rem;
                    color: $grey;
                }

                input[type="password"]:focus {
                    &+.fa-eye-slash {
                        color: $secondary;
                    }
                }
            }

            .forgotPassword {
                a {
                    @media screen and (max-width: $screen-xs) {
                        font-size: 1.2rem;
                        line-height: 2rem;
                    }

                    @media (min-width: $screen-sm) and (max-width: $screen-md) {
                        font-size: 1.2rem;
                        line-height: 2rem;
                    }
                }
            }

            .ploicyTerms {
                position: relative;
                display: grid;
                grid-template-columns: auto 1fr;
                grid-gap: 1rem;

                span {
                    display: block;
                    color: $midGrey;
                    padding-right: 0;
                }
            }

            .passwordHint {
                font-size: 1.4rem;
                line-height: 2rem;
                color: $hintColor;

                strong {
                    font-weight: 500;
                }
            }

            .requiredInfo {
                color: $midGrey;
                margin: 0;
            }

            .validationFields {
                @include flex-justify-center;
                margin: 0 0 4rem;

                .form-control {
                    max-width: 5.6rem;
                    margin: 0 0.5rem;
                    text-align: center;
                }
            }
        }
    }

    .contentSlider {
        position: relative;

        .imageBlock {
            position: relative;
            border-radius: 2.5rem;

            @media screen and (max-width: $screen-sm) {
                margin: 1.8rem 0 2.4rem;
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 32.2rem;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.63) 0%, rgba(0, 0, 0, 0.00) 100%);
                pointer-events: none;
                border-bottom-left-radius: 2.5rem;
                border-bottom-right-radius: 2.5rem;

                @media screen and (max-width: $screen-sm) {
                    height: 16rem;
                }
            }

            img {
                // min-height: 62rem;
                // max-height: 85rem;
                border-radius: 2.5rem;
                height: calc(100vh - 5rem);

                @media screen and (max-width: $screen-sm) {
                    // min-height: 30rem;
                    // max-height: 100%;
                    min-height: auto;
                    max-height: 100%;
                    height: 300px;
                }

                @media screen and (max-width: 375px) {
                    height: 250px;
                }
            }
        }

        .contentBlock {
            position: absolute;
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
            bottom: 6rem;
            padding: 0 2.4rem;

            @media screen and (max-width: $screen-sm) {
                bottom: 3rem;
            }

            * {
                color: $white;
            }

            h2 {
                margin: 0 0 1.5rem;
            }

            p {
                font-family: $fontInter;
                font-size: 1.4rem;
                line-height: 2rem;
                font-weight: 500;
                max-width: 46rem;
                margin: auto;
            }
        }
    }
}

.pageHeadingBlock {
    @include flex-space-between;
    // margin: 0 0 1.6rem;
    margin: 0;

    &.pageSpace {
        padding: 0 30px;
    }

    .pageTitle {
        h2 {
            @include flex-align-center;
            margin: 0;

            .backToPage {
                margin-right: 1rem;

                &:hover,
                &:focus {
                    svg {
                        @media screen and (max-width: $screen-xs) {
                            width: 2rem;
                            height: 2rem;
                        }

                        path {
                            fill: $primary;
                        }
                    }
                }
            }
        }
    }
}


.contentWrapper {
    &.contentBlk {
        .col-12 {
            @media screen and (max-width: $screen-md) {
                margin-bottom: 0;
            }

            @media screen and (max-width: 767px) {
                margin-bottom: 2.4rem;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .col-12 {
        @media screen and (max-width: $screen-md) {
            margin-bottom: 2.4rem;
        }
    }

    // .row:first-child{
    //     .col-12{
    //         @media (min-width: 768px) and  (max-width: $screen-md) {
    //             margin-bottom: 0;
    //         }
    //     }
    // }
    .contentCard {

        &.profileBLOCK {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        @include content-card;

        @media screen and (max-width: 576px) {
            flex-direction: row;
        }
    }

    &.sideContentWrapper {
        .contentCard {
            &:last-child {
                margin-bottom: 0;
            }

            .cardHeading {
                @include flex-space-between;
                padding-bottom: 1.2rem;
                border-bottom: 1px solid $offgrey;
                margin: 0 0 2rem;

                h5 {
                    font-size: 2rem;
                    line-height: 2.8rem;
                    font-weight: 400;
                    margin: 0;

                    @media screen and (max-width: $screen-lg) {
                        font-size: 1.6rem;
                        line-height: 2.4rem;
                        font-weight: 500;
                    }
                }

                .btn {
                    font-size: 1.2rem;
                    line-height: normal;
                    font-weight: 500;
                    padding: 1rem 2rem;
                    max-width: fit-content;
                    height: auto;
                    margin: auto;
                }
            }
        }
    }
}

.tableHeadingBlock {
    @include flex-space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid $borderColor5;
    font-size: 15px;
    background-color: #fff;
    position: relative;
    z-index: 11;
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;

    @media screen and (max-width: $screen-xs) {
        flex-direction: column;
        align-items: flex-start;
    }

    .leftContent {
        @include flex-align-center;

        @media screen and (max-width: $screen-sm) {
            flex-direction: column;
            align-items: flex-start;
        }

        .filtersBlock {
            @include flex-align-center;
            margin-left: 2.4rem;

            @media screen and (max-width: $screen-sm) {
                margin: 0.5rem 0;
            }

            .dropdown {
                &.filterContent {
                    .dropdown-menu {
                        min-width: 62.4rem;

                        @media screen and (max-width: $screen-lg) {
                            min-width: 40rem;
                        }

                        @media screen and (max-width: $screen-md) {
                            min-width: 40rem;
                        }

                        @media screen and (max-width: $screen-sm) {
                            min-width: 40rem;
                        }

                        @media screen and (max-width: $screen-xs) {
                            min-width: 26rem;
                        }
                    }
                }

                &.columnSelection {
                    .dropdown-menu {
                        min-width: 37.4rem;

                        @media screen and (max-width: $screen-lg) {
                            min-width: 32.4rem;
                        }

                        @media screen and (max-width: $screen-sm) {
                            min-width: 30rem;
                        }

                        @media screen and (max-width: $screen-xs) {
                            min-width: 26rem;
                        }
                    }
                }

                .drop {
                    padding: 1rem 1.6rem;
                    border: 1px solid transparent;
                    cursor: pointer;

                    svg {
                        margin-right: 0.8rem;
                    }

                    &.show {
                        border-radius: 0.8rem;
                        border-color: $borderColor1;
                    }

                    @media screen and (max-width: $screen-sm) {
                        border-radius: 0.8rem;
                        border-color: $borderColor1;
                        margin: 0 0.5rem 0.5rem 0;
                        padding: 1rem 1rem;
                    }
                }

                .dropdown-menu {
                    color: $secondary;
                    padding: 0;

                    .dropdownHeading {
                        @include flex-space-between;
                        padding: 1.6rem 2.4rem;
                        border-bottom: 1px solid rgba(65, 65, 65, 0.20);

                        @media screen and (max-width: $screen-lg) {
                            padding: 1.6rem;
                        }

                        * {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                        }

                        a {
                            font-size: 1.4rem;
                            line-height: 2.4rem;
                            color: $secondary;

                            &:hover,
                            &:focus {
                                color: $primary;
                            }
                        }
                    }

                    .dropdownContent {
                        padding: 2.4rem 2.4rem 2rem;
                        max-height: 30rem;
                        overflow-y: auto;

                        @media screen and (max-width: $screen-lg) {
                            padding: 1.6rem;
                        }

                        .cardDrop {
                            margin: 0 0 2.2rem;

                            @media screen and (max-width: $screen-sm) {
                                margin: 0 0 2rem;
                            }

                            &:last-child {
                                margin: 0;
                            }
                        }

                        .title {
                            font-weight: 500;
    font-size: 14px;
    margin-bottom: 20px;
                        }

                        span {
                            font-size: 1.4rem;
                            line-height: 2rem;
                        }

                        .form-group {
                            @include flex-align-center;
                            flex-wrap: nowrap;
                            margin: 0 0 1rem;
                        }

                        input[type=checkbox] {
                            margin-right: 1.6rem;

                            &:checked {
                                &::before {
                                    background: $primary;
                                }

                                &::after {
                                    border-color: $white;
                                }
                            }
                        }

                        .form-check {
                            input[type=checkbox] {
                                margin: 0;

                                &:checked {
                                    &::before {
                                        background: $white;
                                    }

                                }
                            }
                        }

                        .columnItem {
                            @include flex-space-between;
                            min-height: 3.2rem;
                            margin: 0 0 0.8rem;

                            &:last-child {
                                margin: 0;
                            }

                            p {
                                margin: 0;

                                svg {
                                    margin-right: 1rem;
                                }
                            }

                            .form-check {
                                padding: 0;

                                .form-check-input {
                                    position: relative;
                                    width: 2.8rem;
                                    height: 1.4rem;
                                    border-color: $primary;
                                    background-image: none;
                                    margin: 0;
                                    cursor: pointer;

                                    &::before {
                                        @include before-after;
                                        left: 2px;
                                        top: 2px;
                                        width: 8px;
                                        height: 8px;
                                        border-radius: 100%;
                                        background: $primary;
                                        transition: 0.15s ease-in-out;
                                    }

                                    &:checked {
                                        background-color: $primary;
                                        border-color: $primary;

                                        &::before {
                                            left: 16px;
                                            background: $white;
                                        }

                                        &::after {
                                            display: none;
                                        }
                                    }

                                    &:hover,
                                    &:focus {
                                        box-shadow: none;
                                    }
                                }
                            }
                        }
                    }

                    .filterFooter {
                        background-color: $white;
                        padding: 2rem 2.4rem;
                        border-top: 1px solid rgba(0, 0, 0, 0.10);
                        border-bottom-left-radius: 1rem;
                        border-bottom-right-radius: 1rem;

                        @media screen and (max-width: $screen-lg) {
                            padding: 1.6rem;
                        }

                        .btn {
                            font-size: 1.2rem;
                            line-height: 1.8rem;
                            padding: 0.8rem 2.2rem;
                            max-width: 8.4rem;
                            margin: auto;
                            height: auto;
                        }
                    }
                }
            }

            .fullScreenShow {
                padding: 1rem 1.6rem;
                border: 1px solid transparent;
                cursor: pointer;

                svg {
                    margin-right: 0.8rem;
                }

                &.show {
                    border-radius: 0.8rem;
                    border-color: $borderColor1;
                }

                @media screen and (max-width: $screen-sm) {
                    border-radius: 0.8rem;
                    border-color: $borderColor1;
                    margin: 0 0.5rem 0.5rem 0;
                    padding: 1rem 1rem;
                }
            }

            .deleteSelection {
                padding: 1rem 1.6rem 1rem 0rem;
                border: 1px solid transparent;
                cursor: pointer;

                &.deleteBLK {
                    button {
                        &:disabled {
                            color: rgba(65, 65, 65, 0.5);
                            svg {
                                path {
                                    fill: rgba(65, 65, 65, 0.5);
                                }
                            }
                        }
                    }
                } 

                button {
                    color: rgba(65, 65, 65);
                    opacity: 1;
                    font-size: 1.4rem !important;

                    &:focus {
                        border-radius: none;
                        border-color: #ECECEC;
                    }
                }

                svg {
                    margin-right: 0.8rem;
                }


            }
        }
    }

    .searchForm {
        &.searchBLK {
            .form-control {
                @media screen and (max-width: $screen-lg) {
                    padding: 5px 8px;
                }
            }
        }

        .form-control {
            width: fit-content;
            min-width: fit-content;
            height: inherit;
            padding: 0.6rem 0.8rem 0.6rem 3.6rem;
            border-radius: 0.8rem;
            border: 1px solid $borderColor1;

            @media screen and (max-width: $screen-lg) {
                min-width: 24rem;
            }

            @media screen and (max-width: $screen-sm) {
                min-width: 100%;
            }
        }

        button {
            position: absolute;
            left: 0.8rem;
            top: 0;
            background: transparent;
            width: auto;
            padding: 0;
            border: none;
            border-radius: 0;
        }
    }
}

.tableContentBlock {
    position: relative;

    th {
        background: $lightGreyBackground1;
    }

    th,
    td {
        position: relative;
        color: $offblack;
        border-bottom: 1px solid $borderColor5;
        padding: 4px 1.4rem !important;
        white-space: nowrap;

        &:last-child {
            text-align: left;
        }
    }

    td {
        background: $white;

        &:first-child,
        &:nth-of-type(2) {
            color: $secondary;
        }

        .ticketID {
            font-size: 1.6rem;
            line-height: 2rem;
            font-weight: 500;
        }

        .ticketDate {
            display: block;
            font-size: 1.2rem;
            line-height: 2rem;
            color: $offblack;
        }

        .ticketTitle {
            white-space: normal;
            display: block;
            max-width: 17.4rem;
        }

        .labelBox {
            border-radius: 1.6rem;
            background: #F5FAFF;
            font-size: 1.2rem;
            line-height: 1.8rem;
            font-weight: 500;
            display: inline-block;
            padding: 0.4rem 1.2rem;
            color: #0070FF;
            margin-right: 0.4rem;
        }

        .explanation {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 19rem;
            color: $offblack;
        }
    }

    .checkBox {
        width: 2rem;
        height: 2rem;

        input {
            &::before {
                border-color: $borderColor6;
            }

            &:checked {
                &::before {
                    background: $primary;
                }

                &::after {
                    border-color: $white;
                }
            }
        }
    }
    .score {
        &.scoreBLK {
            width: fit-content;
            padding: 4px 12px;
        }
    }

    .score,
    .statusBox {
        position: relative;
        @include flex-justify-center;
        // padding: 2px 8px 2px 22px;
        width: 60%;
        background: $offgreen;
        border-radius: 1.6rem;
        color: $darkgreen;

      

        &::before {
            @include before-after;
            left: 1.2rem;
            top: 0.8rem;
            width: 0.8rem;
            // height: 0.8rem;
            border-radius: 100%;
            background: $darkgreen;
        }

        &.low,
        &.inProcess {
            background: $offOrange;
            color: $orange1;

            &::before {
                background: $orange1;
            }
        }
    }

    .tableActions {
        .actionsDropdown {
            padding: 0.5rem;
            @include flex-justify-center;
            cursor: pointer;
        }

        .dropdown-menu {
            @include dropdown-menu;
        }
    }
}

.tableFixed {
    .tableSelectionRecord {
        display: inherit;
        align-items: inherit;
        justify-content: inherit;
        flex-wrap: inherit;
        background: inherit;
        border-radius: inherit;
        padding: inherit;
        position: fixed;
        bottom: 0px;
        width: -webkit-fill-available;
        padding: 0rem 2.4rem;
        margin: 0rem 0 0;
        z-index: 2;
    }

    .tableCRT {
        @include flex-space-between;
        @include card-box;
        width: 99.4%;
        position: relative;
        left: -25px;

        @media screen and (max-width:1199px) {
            width: 102.5%;
        }

        @media screen and (max-width:988px) {
            width: 102.8%;
        }

        @media screen and (max-width:840px) {
            width: 103.5%;
        }

        @media screen and (max-width:740px) {
            width: 104%;
        }

        @media screen and (max-width:625px) {
            width: 104.8%;
        }

        @media screen and (max-width:480px) {
            width: 105.3%;
        }

        @media screen and (max-width:440px) {
            width: 106%;
        }

        @media screen and (max-width:380px) {
            width: 107.5%;
        }

        @media screen and (max-width:340px) {
            width: 108.6%;
        }
    }
}


.tableSelectionRecord {
    @include flex-space-between;
    @include card-box;
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 0rem 2.4rem;
    margin: 0rem 0 0;
    z-index: 2;

    @media screen and (max-width: $screen-sm) {
        position: relative;
        z-index: 11;
    }

    @media screen and (max-width: $screen-md) {
        position: relative;
        z-index: 11;
    }

    .selectedItems {
        padding-right: 1rem;

        h6 {
            font-size: 1.4rem;
            line-height: normal;
            font-weight: 500;
            margin: 0;
        }

        p {
            font-size: 1.2rem;
            line-height: 1.8rem;
            color: $offgrey3;
            font-style: italic;
            margin: 0
        }
    }

    .exportItems {
        margin-right: 30px;

        .btn {
            width: 80%;
            min-width: 20.1rem;
            margin: 1rem 0;
            height: 40px;

            @media screen and (max-width: $screen-sm) {
                min-width: 16rem;
            }

            svg {
                margin-right: 1rem;
            }
        }

        @media screen and (max-width: $screen-xs) {
            margin: auto;
        }

        @media screen and (max-width: $screen-sm) {
            margin: auto;
        }
    }
}

.paginationBlock {
    @include flex-align-center;
    min-height: 4.8rem;

    div,
    span {
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 500;
        color: $offgrey4;

        @media screen and (max-width: $screen-xs) {
            span {
                // width: 100%;
            }
        }
    }

    .pageNumber {
        @include flex-justify-center;
        width: 4.2rem;
        height: 3.2rem;
        padding: 0.6rem 0.8rem;
        color: $secondary;
        font-weight: 400;
        border: 1px solid $borderColor1;
        outline: none;
        border-radius: 0.8rem;
        text-align: center;
        margin: 0 1rem;
    }

    .pagination-page-block {
        @media screen and (max-width: $screen-xs) {
            display: block;
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .page-span {
        @media screen and (max-width: $screen-xs) {

            margin-left: 0px !important;
        }
    }

    .totalPages {
        margin-left: 0.3rem;
    }

    .pagination {
        // margin-left: 1.2rem;

        @media screen and (max-width: $screen-xs) {
            margin-left: 0;
        }

        .paginationLink {
            @include flex-justify-center;
            width: 3.2rem;
            height: 3.2rem;
            line-height: 1.6rem;
            background: $borderColor1;
            border-radius: 0.8rem;
            margin-left: 1.2rem;

            svg {
                width: 1rem;
                height: 1rem;
            }

            &:hover,
            &:focus {
                background: $lightGreyBackground2;

                svg path {
                    fill: $primary;
                }
            }
        }
    }

    .paginationDropdown {
        .page-drop {
            background: $lightGreyBackground2;
            width: 2.2rem;
            font-weight: 300;
            border: none;
            color: $secondary;
            height: 3.2rem;
            line-height: 1.6rem;
            background: $borderColor1;
            border-radius: 0.8rem;
            margin-left: 1.2rem;
            margin-right: 1rem;
        }

        .dropdown-menu {
            padding: 0;
            border-color: $borderColor1;
            z-index: 99;

            ul {
                padding: 0;
                margin: 0;
                list-style: none;
                max-height: 300px;
                overflow-y: auto;

                li {
                    font-size: 1.4rem;
                    line-height: 2rem;
                    margin: 0;
                    padding: 1rem 1.6rem;
                    cursor: pointer;
                }
            }
        }
    }
}

.buttonGroup {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2.4rem;

    @media screen and (max-width: $screen-xs) {
        grid-gap: 1rem;
    }

    .btn {
        svg {
            margin-right: 1rem;
        }
    }
}

.overlayBlock {
    // display: none;
    // position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.80);
    backdrop-filter: blur(5px);

    .overlayContent {
        @include flex-column;
        align-items: center;
        padding: 4rem 7.3rem 1.2rem 7.3rem;

        img {
            width: 7.8rem;
            height: 7.8rem;
            margin: 0 0 2.4rem;
        }

        h6 {
            font-size: 1.8rem;
            line-height: 3.2rem;
            margin: 0 0 0.6rem;
        }

        .btn {
            max-width: 16.6rem;
        }
    }
}

.nav-pills {
    .nav-item {
        margin: 0 0 1.2rem;

        &:last-child {
            margin: 0;
        }
    }

    .nav-link {
        position: relative;
        background: $lightGreyBackground;
        font-size: 1.4rem;
        line-height: 2.8rem;
        color: $secondary;
        border-radius: 0.8rem;
        padding: 0.8rem 1.6rem;

        &.active {
            background: $primary2;
            color: $primary;
        }
    }

    .show>.nav-link {
        background: $primary2;
        color: $primary;
    }
}

.accordion {
    .accordion-item {
        border: none;
        border-radius: 1rem;
        margin: 0 0 2.4rem;

        &:last-child {
            margin: 0;
        }

        .accordion-header {
            .accordion-button {
                background: transparent;
                font-size: 1.8rem;
                line-height: 2.8rem;
                font-weight: 400;
                color: $secondary;
                padding: 1.6rem 2.4rem;
                border-top-left-radius: 1rem;
                border-top-right-radius: 1rem;
                outline: none;
                box-shadow: none;
                height: auto;

                @media screen and (max-width: $screen-md) {
                    font-size: 1.6rem;
                    line-height: 2.6rem;
                    padding: 1.6rem;
                }

                &::after {
                    background-image: url(../images/chevron.png);
                    width: 2.4rem;
                    height: 2.4rem;
                    background-size: cover;

                    @media screen and (max-width: $screen-md) {
                        width: 1.6rem;
                        height: 1.6rem;
                    }
                }

                &:not(.collapsed) {
                    background: $white;
                    border-bottom: 1px solid $borderColor;
                }

                &:hover,
                &:focus,
                &:focus-within {
                    outline: none;
                    box-shadow: none;
                    border-color: $borderColor;
                }
            }
        }

        .accordion-body {
            font-size: 1.4rem;
            line-height: 2.8rem;
            font-weight: 400;
            color: $secondary;
            padding: 1.6rem 2.4rem;
        }
    }
}

.pageHeadingBlock {
    &.commonHeading {
        @media screen and (max-width: $screen-sm) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &.campSpace {
        padding: 0 20px !important;
        margin: 0 !important;

        h3 {
            margin-bottom: 0.6rem;
        }
    }

    //   .icpDataBlk {
    //     li {
    //         margin-bottom: 0 !important;
    //     }
    //   }
    .icpDataList {
        @include flex-align-center;
        padding: 0;
        margin: 0;
        list-style: none;
        gap: 10px;

        @media screen and (max-width: $screen-sm) {
            // margin: 1rem 0 0;
        }

        li {
            @include flex-justify-center;
            flex-wrap: nowrap;
            background: $white;
            padding: 0.8rem 0.7rem;
            border-radius: 0.8rem;
            border: 1px solid $white;
            // margin: 0 1.2rem 0rem 0;


            &:last-child {
                margin-right: 0 !important;
                padding: 0.8rem;
                border-color: $primary;

                @media screen and (max-width: $screen-sm) {
                    position: absolute;
                    top: 87px;
                    right: 13px;
                }
            }

            .circle {
                width: 1rem;
                height: 1rem;
                border-radius: 100%;

                &.searches {
                    background: radial-gradient(66.83% 50% at 50% 50%, #FE8A81 3.79%, #F0675C 100%);
                    filter: drop-shadow(0px 0px 10.111px rgba(255, 255, 255, 0.20)) drop-shadow(0px 0px 10.111px rgba(240, 103, 92, 0.50));
                }

                &.downloads {
                    background: radial-gradient(66.83% 50% at 50% 50%, #FFC98A 3.79%, #F5A243 100%);
                    filter: drop-shadow(0px 0px 10.111px rgba(255, 255, 255, 0.20)) drop-shadow(0px 0px 10.111px rgba(245, 162, 67, 0.50));
                }

                &.credits {
                    background: radial-gradient(66.83% 50% at 50% 50%, #40C789 3.79%, #14BA6D 100%);
                    filter: drop-shadow(0px 0px 10.111px rgba(255, 255, 255, 0.20)) drop-shadow(0px 0px 10.111px rgba(20, 186, 109, 0.50));
                }

                &.prospect {
                    background: radial-gradient(66.83% 50% at 50% 50%, #af7ecf 3.79%, #c676c7 100%);
                    filter: drop-shadow(0px 0px 10.111px rgba(255, 255, 255, 0.20)) drop-shadow(0px 0px 10.111px rgba(216, 204, 223, 0.5));
                }
            }

            span {
                color: $secondary;

                &.text {
                    font-size: 1.2rem;
                    margin: 0 1rem;
                    font-weight: 500;
                    white-space: nowrap;
                }

                &.number {
                    font-weight: 600;
                    font-size: 1.2rem;
                }
            }
        }
    }
}

.twoGroups {
    display: grid;
    grid-template-columns: 1fr 15.4rem;
    grid-gap: 2.4rem;

    @media screen and (max-width: $screen-xs) {
        grid-template-columns: 1fr;
    }
}

.Toastify__close-button {
    background: $white;
    border-radius: 0.4rem;
    width: auto;
    height: auto;
    min-width: 3rem;
    min-height: 3rem;
}

.react-confirm-alert-overlay {
    background: rgba(255, 255, 255, 0.5);
}

.confirmation-popup {
    .react-confirm-alert-body {
        width: 100%;
        border: 1px solid #E55426;
        font-size: 18px;

        @media screen and (max-width: $screen-xs) {
            width: 100% !important;
            padding: 15px;
            font-size: 16px;
        }

        h1 {
            font-size: 17px;
            border: 0.1px solid $primary1;
            padding: 5px 10px;
        }

        .react-confirm-alert-button-group {
            justify-content: center;

            button {
                border-radius: 4px;
                min-width: 80px;
                border: 1px solid transparent !important;

                &:first-child {
                    background-color: $primary;
                    color: #fff;
                    font-weight: 700;
                    font-size: 18px;
                }

                &:last-child {
                    border: 1px solid $primary !important;
                    background-color: inherit;
                    color: $primary;
                    margin: 0;
                    font-size: 18px;
                }
            }
        }
    }
}

.downloadbtn {
    margin-bottom: 10px;
    height: 100px;
    width: 180px;
    border-radius: 10px;
    border: none;
    background-color: white;
    color: $black;
    margin-bottom: 10px;
    display: "block";
    text-align: "center";
    font-weight: 400;
    flex-wrap: wrap;
    opacity: 0.7;
    font-size: 14px;
    box-shadow: rgba(255, 69, 50, 0.35) 2.4px 2.4px 3.2px;

    &:hover,
    &:focus {
        background-color: $primary2;
        color: $primary;
    }

    @media screen and (max-width: $screen-xs) {
        width: 100% !important;
        height: 60px;
        font-size: 10px;
        padding: 10px !important;
    }
}

.highlight {
    background-color: $primary2;
    color: $primary;

    @media screen and (max-width: $screen-xs) {
        width: 100% !important;
    }
}

.scroll {
    position: fixed;
    right: 10%;
    bottom: 25%;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.scroll.show {
    opacity: 0.5;
    display: block;
}

.btn-scroll {
    position: absolute;
    z-index: 10;
    //   display: block;
    transform: translateX(50%);
    border-color: $primary;
    left: 50%;
    background-color: #ECECEC;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.10);
    border-radius: 50%;
    width: 42px;
    height: 42px;

    i {
        color: $primary;
        opacity: 1;

        font-weight: bold;
        // font-size: 15px;
    }

    &:hover,
    &:focus {
        background-color: $primary2;
        color: $primary;
    }
}

@media screen and (max-width: $screen-xs) {
    .scroll {
        bottom: 45%;
        right: 81px;
        top: 80%;
    }

}

@media (min-width: $screen-sm) and (max-width: $screen-md) {
    .scroll {
        bottom: 45%;
        right: 10%;
        top: 80%;
    }
}

.scroll-icon {
    text-align: center;
}

@media screen and (max-width: 600) {
    ul.download_list_block {
        position: relative;
    }

    .download_blockk {
        position: absolute;
        top: -45px;
        right: 2px;
        height: 35px;
        width: 35px;
    }
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
    padding: 1.2rem;
    display: flex;
    justify-content: end;
    padding-right: 2rem;
}

.react-datepicker__view-calendar-icon input {
    border: none;
    border-bottom: 1px solid #E6E6E6;
}

.buyCredits {
    .btn {
        // margin-top: 2rem;
    }

    &.buyCreditBlk {
        @media screen and (max-width: $screen-xs) {
            margin: 0;
        }
    }

    @media screen and (max-width: $screen-xs) {
        margin: auto;
    }
}

.disabled-link {
    cursor: not-allowed;
    color: #aaa;
    text-decoration: none;
}

.disabled-link:hover {
    color: #aaa;
}

.apexcharts-menu {
    padding: 0 !important;
}

.resend-link {
    margin: auto;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    height: auto;
    padding: 8px 50px !important;
}

.p-listbox-list-wrapper {
    .p-virtualscroller {
        ul {
            li {
                height: 30px !important;
            }
        }
    }
}

.p-listbox-header {
    position: sticky;
}

.list-box {

    font-size: 10px;
    // border: 1px solid $offgrey;
    padding: 10px;

    // max-height: 500px;
    .p-virtualscroller {
        margin-top: 15px;
        padding: 10px;
        max-height: 300px;
        border: 1px solid $offgrey;

        .p-virtualscroller-content {
            padding: 20px;

        }
    }
}

.itemtemplate {
    display: flex;
    justify-content: space-between;
    padding: 0.3rem 0rem;

    &:hover,
    &:focus {
        background: rgb(231, 235, 241);
        cursor: pointer;
    }
}

.max-msg {
    font-style: italic;
    font-size: 1.16rem;
    line-height: 1.5rem;
    color: $offgrey4;
    margin: 0.8rem 0 0;

    strong {
        font-weight: 500;
    }
}

.contentWrapper {

    &.myDownloadList,
    &.ABMresult {
        .tableHeadingBlock {
            @media screen and (max-width:767px) {
                flex-direction: inherit !important;
            }

            .leftContent {
                @media screen and (max-width:767px) {
                    flex-direction: inherit;
                    align-items: inherit;
                }
            }

        }
    }
}

.contentWrapper {
    &.ICPResults {
        .tableHeadingBlock {
            @media screen and (max-width:767px) {
                flex-direction: inherit !important;
            }

            .leftContent {
                @media screen and (max-width:767px) {
                    flex-direction: inherit;
                    align-items: inherit;
                }
            }

        }
    }
}

.CampaignBlk {
    h6 {
        margin-bottom: 0;
    }
}

.commonHeadBlock {
    padding: 0 20px;

    h6,
    h3 {
        margin: 0;
    }

    .icpDataList {
        li {
            @media screen and (max-width:700px) {
                // margin: 0 1.2rem 0.6rem 0;
            }
        }

        &.download_list_block {
            li {
                @media screen and (max-width:1200px) {
                    // margin: 0 0.7rem 0.6rem 0;
                }
            }
        }
    }

}

.commonContentBlock {
    padding: 10px 20px;
}